import { Layout, Menu, Dropdown } from "antd";
import React from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, LineChartOutlined, FormOutlined, InfoCircleFilled, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter } from "react-router-dom";
import Garaza from "./garaza";
import Home from "./parkingTvrtka";
import Login from "./logIn";
import SampleForm from "./sampleform";
import MainMenu from "./mainmenu";
import PasswordChange from "./passwordChange";
import ParkingTvrtka from "./parkingTvrtka";
import UvjetiKoristenja from "./uvjetikoristenja";
import Test from "./test";
import ParkingTvrtke from "./parkingTvrtke";
import Util from "../util/util";
import { browserHistory } from "react-router";
import { withRouter } from "react-router-dom";
import logo from "../images/Untitled-2.png";
import database from "../util/database";
import api from "../util/api";

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        let collapsed = Util.getCookie("navcollapsed");

        this.state = {
            collapsed: collapsed === "true" ? true : false,
            locationLabel: "",
            isMobileDevice: Util.isMobileDevice(),
            username:  "",
            user: null,
        };
    }

    toggle = () => {
        Util.setCookie("navcollapsed", !this.state.collapsed);
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    onLinkClick = (e) => {
        this.setState({ locationLabel: e.target.pathname });
    };

    login = () => {};

    onLoginSuccess = (user) => {
        //var roles = user.roles.split(",");

        api.get("InitData",(data)=>{
            database.set("tipParkingSlots", data.TipParkingSlots);
            database.set("mjesta", data.Mjesta);
            database.set("tipSlots", data.TipSlots);
            database.set("daniTjedna", data.DaniTjedna);
            database.set("tipJediniceMjere", data.TipJediniceMjere);

            let uloge = user.uloge.split(",").map((x)=> parseInt(x));
            database.set("user", user);
            database.set("uloge", uloge);
            database.set("username", user.username);
            this.setState({ user: user, username: user.username });
            window.location = "/#";
            //window.location.reload();
        })


    };

    menuToggle = (data) => {
        this.setState({ showMobileMenu: data });
    };

    render() {
        let logoClassName = this.state.collapsed ? "logoCollapsed" : "logo";
        logoClassName = "logo";

        const u = database.get("user");
        let isLoggedIn = u != null;
        let notLoggedIn = !isLoggedIn;
        let isMobileDevice = Util.isMobileDevice();
        const menu = (
            <Menu>
                <Menu.Item
                    key="mbm0"
                    onClick={() => {
                        window.location = "#/passwordchange";
                    }}
                >
                    Promjena lozinke
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                    key="mbm1"
                    onClick={() => {
                        Util.logout();
                    }}
                >
                    Odjava
                </Menu.Item>
            </Menu>
        );

        return (
            <HashRouter>
                <Layout>
                    {isMobileDevice ? (
                        <MainMenuMobile username={this.state.user ? this.state.user.username : ""} roles={this.state.roles} isLoggedIn={isLoggedIn}></MainMenuMobile>
                    ) : (
                        <Sider theme="white" collapsedWidth={50} trigger={null} collapsible collapsed={this.state.collapsed}>
                            {this.state.collapsed ? (
                                <></>
                                // <img src={logo} style={{ margin: "auto", height: "50px", width: "50px", float: "left" }}></img>
                            ) : (
                                // <div className={logoClassName}>
                                //     HP-ING <p style={{  fontSize: "9px", color: "rgb(134 134 134)", marginTop: "-9px" }}>project manager</p>

                                // </div>
                                <div className="menu-logo">
                                    <span style={{ marginTop: "20px", color: "white", display: "inline-block" }}>
                                        <p>ROTO</p>
                                        <p style={{ marginTop: "-20px", fontSize: "9px", color: "#757575" }}>garage manager</p>
                                    </span>
                                    <img src={logo} style={{ height: "64px", width: "64px", float: "left" }}></img>
                                </div>
                                // <Image className={"logo"} style={{ height: "64px", width: "64px", float: "right" }} src={logo}></Image>
                            )}
                            <MainMenu roles={this.state.roles} isLoggedIn={isLoggedIn}></MainMenu>
                        </Sider>
                    )}

                    <Layout className="site-layout">
                        {!isMobileDevice && isLoggedIn && (
                            <Header className="site-layout-background" style={{ padding: 0 }}>
                                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: "trigger",
                                    onClick: this.toggle,
                                })}
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ fontSize: "17px", fontWeight: "bold" }}>{this.state.locationLabel}</span>
                                <div style={{ float: "right", marginRight: "20px" }}>

                                    <Dropdown overlay={menu} trigger={["click"]}>
                                        <div  style={{cursor: "pointer"}}>
                                             {this.state.username || database.get("username")} <UserOutlined></UserOutlined>
                                        </div>
                                    </Dropdown>
                                </div>
                            </Header>
                        )}
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: "24px 16px",
                                padding: 24,
                                minHeight: 280,
                            }}
                        >
                            <Switch>
                                {isLoggedIn && <Route path="/parkingTvrtke" component={ParkingTvrtke}></Route>}
                                {isLoggedIn && <Route path="/parkingTvrtka" component={ParkingTvrtka}></Route>}
                                {isLoggedIn && <Route path="/garaza" component={Garaza}></Route>}
                                {(isLoggedIn || notLoggedIn) && <Route path="/parkiranje" component={Test}></Route>}   
                                {(isLoggedIn || notLoggedIn) && <Route path="/uvjetikoristenja" component={UvjetiKoristenja}></Route>}               
                                {isLoggedIn && <Route path="/passwordchange" component={PasswordChange}></Route>}
                                {isLoggedIn && <Route path="/sampleform" component={SampleForm}></Route>}
                                {isLoggedIn && <Route component={ParkingTvrtka}></Route>}
                                {/* {isLoggedIn && <Route component={NotFound}></Route>} */}
                                {isLoggedIn && <Route path="/" component={() => <Login success={this.onLoginSuccess}></Login>}></Route>}
                                {notLoggedIn && <Route path="/" component={() =>  <Login success={this.onLoginSuccess}></Login>}></Route>}
                                {notLoggedIn && <Route path="/login" component={() =>  <Login success={this.onLoginSuccess}></Login>}></Route>}

                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </HashRouter>
        );
    }
}

export default Navigation;
