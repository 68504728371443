import React, { useState, useContext, useEffect } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import api from "../util/api";
import { Button, Row, Col, Input } from "antd";
import Util from "../util/util";
import database from "../util/database";
import ParkingTvrtkaEdit from "../components/parkingTvrtkaEdit";
import Spinner from "./spinner";
import { Select } from "antd";

const { Option } = Select;

function ParkingTvrtke(props) {
    const [loaded, setLoaded] = useState(false);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tvrtke, setTvrtke] = useState([]);

    useEffect(() => {
        if (!loaded) {
            setLoading(true);

            let u = database.get("user");
            let uloge = u.uloge.split(",");
            if(uloge.includes("1")){
                api.get(
                    "GetAllParkingTvrtka",
                    (data) => {

                        debugger;
                        if (data.Success) {
                            setLoading(false);
                            setTvrtke(data.ParkingTvrtke);
                        } else {
                            alert(data.ErrorMessage);
                        }
                    },
                    (data) => {

                        debugger;
                        alert(data);
                    }
                );
            }else{
                window.location = "#/";
                return;
            }
        }
        setLoaded(true);
    });

    function onSave(data) {
        setLoading(true);
        setEdit(false);

        api.post(
            "AddParkingTvrtka",
            data,
            (data) => {
                if (data.Success) {
                    window.location.reload();
                } else {
                    alert(data.ErrorMessage);
                }
            },
            (data) => {
                alert(data);
            }
        );
    }

    function onCancel() {
        setEdit(false);
    }

    return loading ? (
        <Spinner></Spinner>
    ) : (
        <Row type="flex" style={{ minHeight: "80vh" }} className="fadeIn animated">
            <Col span={24}>
                {!edit ? (
                    <>
                        <button
                            onClick={() => {
                                setEdit(true);
                            }}
                        >
                            Nova tvrtka
                        </button>
                        <br></br>
                        <br></br>

                        {tvrtke &&
                            tvrtke.map((tvrtka, index) => {
                                return (
                                    <div
                                        className="link"
                                        key={"rowslot" + index}
                                        onClick={() => {
                                            window.location = "#/parkingTvrtka?parkingTvrtkaID=" + tvrtka.ParkingTvrtkaID;
                                        }}
                                    >
                                        <b>{tvrtka.Naziv}</b> - {tvrtka.PunaAdresa}
                                    </div>
                                );
                            })}
                    </>
                ) : (
                    <>
                        <ParkingTvrtkaEdit onCancel={onCancel} onSave={onSave}></ParkingTvrtkaEdit>
                    </>
                )}
            </Col>
        </Row>
    );
}

export default ParkingTvrtke;
