import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Popover } from "antd";

import util from "../util/util";
import database from "../util/database";
import api from "../util/api";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import { ToolOutlined, QuestionCircleOutlined } from "@ant-design/icons";



function Upitnik(props) {
    const content = props.text;
    return <></>
    return props.disabled ? null : <Popover content={props.text}><QuestionCircleOutlined style={{cursor:"pointer"}} /></Popover>;
}

export default Upitnik;
