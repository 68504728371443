import React from "react";
import api from "../util/api";
import { message, Row, Button, Form, Input } from "antd";
import Spinner from "../components/spinner";
import database from "../util/database";

class PasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    success = (data) => {
        message.info("Uspješno ste promijenili lozinku.");
    };

    fail = () => {
        this.setState({ loading: false });
    };

    updateSuccess = (data) => {
       
        if (data.ErrorMessage) {
            message.error(data.ErrorMessage);
            this.setState({ loading: false });
        } else {
            message.info("Uspješno ste promijenili lozinku!");
            window.location = "#/home";
        }
    };

    updateFail = () => {
        this.setState({ loading: false });
    };

    onFinish = (values) => {
        this.setState({ loading: true });
        values.OperaterID = database.get("user").operaterID;
        api.post("ChangePassword", values, this.updateSuccess, this.updateFail);
    };

    onFinishFailed = (values) => {
        debugger;
    };

    render() {
        const layout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 15 },
        };

        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Form {...layout} red style={{ width: "400px" }} className="login-form" onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
                        <Form.Item label="Stara lozinka" name="OldPassword" rules={[{ required: true, message: "Molimo unesite staru lozinku" }]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item label="Nova lozinka" name="NewPassword" rules={[{ required: true, message: "Molimo unesite novu lozinku" }]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item label="Potvrdite novu lozinku" name="ConfirmPassword" rules={[{ required: true, message: "Molimo potvrdite novu lozinku" }]}>
                            <Input.Password />
                        </Form.Item>
                        <Button style={{ float: "right" }} htmlType="submit">
                            Spremi
                        </Button>
                    </Form>
                )}
            </Row>
        );
    }
}
export default PasswordChange;
