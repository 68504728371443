import React, { useState } from "react";
import util from "../util/util";
import database from "../util/database";
import api from "../util/api";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import { ToolOutlined } from "@ant-design/icons";

function UvjetiKoristenja(props) {

    return (
        <div style={{ textAlign: "center", height: "100%", verticalAlign: "middle", padding: "30px", margin: "10px", marginTop: "50px", backgroundColor: "rgb(103 169 114 / 18%)", borderRadius: "20px" }}>
            <ToolOutlined style={{ fontSize: "100px", color: "#507681" }}></ToolOutlined>
            <p style={{ width: "100%", textAlign: "center", color: "rgb(131 129 129)" }}>U izradi...</p>
        </div>
    );
}

export default UvjetiKoristenja;