import React, { useState, useContext, useEffect } from "react";
//import { Slider } from "antd";

import api from "../util/api";
import { Form, FormInstance, Input, Button, InputNumber, Row, Divider, Checkbox, Select, Col } from "antd";
import Util from "../util/util";
import database from "../util/database";
import Spinner from "../components/spinner";

import { Tabs } from "antd";
import { UserOutlined, EditOutlined, MinusCircleFilled, DeleteOutlined, LogoutOutlined } from "@ant-design/icons";
import GarazaEdit from "../components/garazaEdit";
import PeriodEdit from "../components/periodEdit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import redsemaphore from "../images/redlight.png";
import { Tooltip } from "antd";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import car from "../images/car.png";
import { Switch } from "antd";
import Upitnik from "./upitnik";
import Iframe from "react-iframe";

const { TextArea } = Input;
const { Search } = Input;

const { Option } = Select;

function Garaza(props) {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSemaphore, setLoadingSemaphore] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);

    const [garaza, setGaraza] = useState(null);
    const [displayPoruke, setDisplayPoruke] = useState(null);
    const [timeoutKoraci, setTimeoutKoraci] = useState(null);
    const [mode, setMode] = useState("default");
    const [slotovi, setSlotovi] = useState([]);

    const [tipSlots, setTipSlots] = useState([]);
    const [tipJediniceMjere, setTipJediniceMjere] = useState([]);
    const [tipParkingSlots, setTipParkingSlots] = useState(null);
    const [changes, setChanges] = useState(null);
    const [cjenik, setCjenik] = useState([]);
    const [periodiGodine, setPeriodiGodine] = useState([]);
    const [postavkeChanges, setPostavkeChanges] = useState(false);
    const [displayPorukeChanges, setDisplayPorukeChanges] = useState(false);
    const [timeoutKoraciChanges, setTimeoutKoraciChanges] = useState(false);

    const [mjesta, setMjesta] = useState([]);
    const [activeTab, setActiveTab] = useState("1");

    const [dodavanjePerioda, setDodavanjePerioda] = useState(false);

    const [ispraznitiSlot, setIspraznitiSlot] = React.useState(false);

    const [selectedPeriodGodine, setSelectedPeriodGodine] = useState(0);
    const [selectedSlot, setSelectedSlot] = useState(0);
    const [selectedSlotID, setSelectedSlotID] = useState(0);
    const [razlogPraznjenjaSlota, setRazlogPraznjenjaSlota] = useState();
    const [selectedDan, setSelectedDan] = useState(0);
    const [slotOcuppancies, setSlotOcuppancies] = useState([]);
    const [currentParkingTrans, setCurrentParkingTrans] = useState();
    const [semaphoreStatus, setSemaphoreStatus] = useState();
    const [bojaSemaforaID, setBojaSemaforaID] = useState(1);
    const [semaforVrijemeZadnjeIzmjene, setSemaforVrijemeZadnjeIzmjene] = useState();
    const [RPSystemCrveniSemaforOnMaxBrojPokusaja, setRPSystemCrveniSemaforOnMaxBrojPokusaja] = useState();
    const [camSystemCrveniSemaforOnMaxBrojPokusaja, setCamSystemCrveniSemaforOnMaxBrojPokusaja] = useState();
    const [displayPorukaIFrameURL, setDisplayPorukaIFrameURL] = useState();
    const [showDisplayPoruka, setShowDisplayPoruka] = useState();

    const antIcon = <SyncOutlined style={{ fontSize: 24, color: "#626262" }} spin />;

    useEffect(() => {
        if (!loaded) {
            const params = Util.getParametersFromUrl(window.location);
            setLoading(true);

            api.get("GetRotoGaraza?id=" + params.id, (data) => {
                setGaraza(data);
                setBojaSemaforaID(data.BojaSemaforaID);
                setPeriodiGodine(data.PeriodiGodine);
                setCjenik(data.Cjenik);
                setSlotovi(data.Slotovi);
                setDisplayPoruke([...data.DisplayPoruke]);
                setTimeoutKoraci([...data.TimeoutKoraci]);
                setDisplayPorukaIFrameURL(data.DisplayPorukaIFrameURL);
                database.set("rotoGarazaID", data.RotoGarazaID);

                var sdp = database.get("showDisplayPoruka");
                if (sdp !== null) {
                    setShowDisplayPoruka(sdp);
                } else {
                    setShowDisplayPoruka(true);
                }
                updateGarageDetailedStatus();
                setLoading(false);
            });

            setLoaded(true);
            const tipSlotss = database.get("tipSlots");
            const tipJediniceMjere = database.get("tipJediniceMjere");
            const tipParkingSlotss = database.get("tipParkingSlots");
            const mjestas = database.get("mjesta");
            setTipSlots(tipSlotss);
            setTipParkingSlots(tipParkingSlotss);
            setTipJediniceMjere(tipJediniceMjere);
            setMjesta(mjestas);

            setInterval(() => {
                updateGarageDetailedStatus();
            }, 3000);
        }
    });

    function updateGarageDetailedStatus() {
        if (activeTab == 1) {
            const params = Util.getParametersFromUrl(window.location);
            api.get("getgaragedetailedstatus?rotoGarazaID=" + params.id, (data) => {
                setSlotOcuppancies(data.SlotOcuppancies);
                setCurrentParkingTrans(data.CurrentParkingTrans);
                // console.log(data.SemaphoreStatus);
                setSemaphoreStatus(data.SemaphoreStatus);
                setBojaSemaforaID(data.SemaphoreStatus.BojaSemaforaID);
                setSemaforVrijemeZadnjeIzmjene(data.SemaphoreStatus.SemaforVrijemeZadnjeIzmjene);
                setLoading(false);
                setLoadingDetails(false);
            });
        }
    }

    // function tipParkingSlotChange(index, tipID) {
    //     debugger;
    //     setChanges(true);
    //     let newSlots = [...slotovi];
    //     newSlots[index].TipParkingSlotID = tipID;
    //     newSlots[index].TipSlotID = tipID == 2 && newSlots[index].TipSlotID == 2 ? 1 : newSlots[index].TipSlotID;
    //     setSlotovi(newSlots);

    //     // let ts = [...tipSlots];
    //     // setTipSlots(ts);
    // }

    function tipSlotChange(index, tipID) {
        setChanges(true);
        let newSlots = [...slotovi];
        newSlots[index].TipSlotID = tipID;
        setSlotovi(newSlots);
    }

    function save() {
        const data = {
            RotoGarazaID: garaza.RotoGarazaID,
            Slotovi: [...slotovi],
        };

        setLoading(true);
        api.post("SaveParkingSlots", data, (newData) => {
            setSlotovi(newData.Slotovi);
            setLoading(false);
            setChanges(false);
        });
    }

    function add() {
        setLoading(true);
        let slt = [...slotovi];
        let newSlot = { Oznaka: "Slot" + (slt.length + 1), RotoGarazaID: garaza.RotoGarazaID, TipSlotID: 1 };
        api.post("AddParkingSlot", newSlot, (newData) => {
            slt.push(newData);

            setSlotovi(slt);
            setLoading(false);
        });
    }

    function remove(index) {
        if (index > -1) {
            setLoading(true);
            let newSlot = slotovi[index];
            api.post("DeleteParkingSlot", newSlot, (newData) => {
                if (newData.Success) {
                    let slt = [...slotovi];
                    slt.splice(index, 1);
                    setSlotovi(slt);
                    setLoading(false);
                } else {
                    alert(newData.ErrorMessage);
                }
            });
        }
    }

    function onOznakaChange(e, index) {
        setChanges(true);
        let newSlots = [...slotovi];
        newSlots[index].Oznaka = e.target.value;
        setSlotovi(newSlots);
    }

    function onPostavkaChange(e, id) {
        debugger;
        setPostavkeChanges(true);
        let data = structuredClone(garaza);
        if (e && e.target) {
            let val = e.target ? e.target.value : e;
            id = e.target ? e.target.id : id;
            data[id] = val;
        } else {
            if (e === true || e === false) {
                e = e ? 1 : 0;
            }

            data[id] = e;
        }

        setGaraza(data);
    }

    function saveGaraza(g) {
        setLoading(true);
        debugger;
        api.post("AddRotoGaraza", g, () => {
            setLoading(false);
            setMode("default");
            setPostavkeChanges(false);
        });
    }

    function tabChange(data) {
        console.log(data);
        setActiveTab(data);
    }

    function onCancel() {
        setMode("default");
    }

    function onGarazaEdit(data) {
        let g = structuredClone(garaza);
        g.Naziv = data.Naziv;
        g.MjestoID = data.MjestoID;
        g.Adresa = data.Adresa;
        setGaraza(g);
        saveGaraza(g);
    }

    function redSemaphoreOn() {
        setLoadingSemaphore(true);
        api.post("RedSemaphoreOn", garaza, (data) => {
            setLoadingSemaphore(false);
            setBojaSemaforaID(data.BojaSemaforaID);
            setGaraza(data);
            updateGarageDetailedStatus();
        });
    }

    function redSemaphoreOff() {
        setLoadingSemaphore(true);
        api.post("RedSemaphoreOff", garaza, (data) => {
            setLoading(false);
            setBojaSemaforaID(data.BojaSemaforaID);
            setGaraza(data);
            setLoadingSemaphore(false);
        });
    }

    function onFinish() {}

    const handleCloseIspraznitiSlot = (ok, slotID) => {
        setIspraznitiSlot(false);

        let data = {
            SlotID: selectedSlotID,
            Razlog: razlogPraznjenjaSlota,
        };

        if (ok == 1) {
            api.post("IsprazniSlot", data, (data) => {
                if (data.Success) {
                    updateGarageDetailedStatus();
                } else {
                    alert(data.ErrorMessage);
                }
                // window.location = "#/parkingTvrtka?parkingTvrtkaID=" + database.get("parkingTvrtkaID");
            });
        }
    };

    const onEdit = (index, action) => {
        if (action === "add") {
            addPeriod();
        } else {
            removePeriod(index);
        }
    };

    function addPeriod() {
        setDodavanjePerioda(true);
    }

    function removePeriod(index) {
        let data = periodiGodine[index];

        api.post("DeletePeriodGodine", data, (result) => {
            let newPeriodi = [...periodiGodine];

            newPeriodi.splice(index, 1);

            setPeriodiGodine(newPeriodi);
            setDodavanjePerioda(false);
            setLoading(false);
        });
    }

    function onPeriodSave(data) {
        setLoading(true);

        let newPeriodi = [...periodiGodine];
        data.Slotovi = [];
        data.RotoGarazaID = database.get("rotoGarazaID");
        api.post("AddPeriodGodine", data, (result) => {
            newPeriodi.push(result);
            setPeriodiGodine(newPeriodi);
            setDodavanjePerioda(false);
            setLoading(false);
        });
    }

    function onPeriodCancel() {
        setDodavanjePerioda(false);
    }

    function onPeriodChange(key) {
        console.log(getIndex(key));
        setSelectedPeriodGodine(getIndex(key));
    }

    function onSlotChange(key) {
        console.log(getIndex(key));
        setSelectedSlot(getIndex(key));
    }

    function onDanChange(key) {
        console.log(getIndex(key));
        setSelectedDan(getIndex(key));
    }

    function onRangeChange(e, arr, thumbIndex) {
        let newPeriodiGodine = [...periodiGodine];
        let newCjenik = newPeriodiGodine[selectedPeriodGodine].Slotovi[selectedSlot].DaniTjedna[selectedDan].Cjenik;

        if (newCjenik[thumbIndex].PocetakInt == arr[thumbIndex]) {
            //kliknuli smo na postojeći thumb
            removeRange(thumbIndex);
            return;
        }

        addRange(e.target.value);
    }

    function addRange(arr) {
        let newPeriodiGodine = [...periodiGodine];

        let newCjenik = newPeriodiGodine[selectedPeriodGodine].Slotovi[selectedSlot].DaniTjedna[selectedDan].Cjenik;
        let newCjenikInt = newPeriodiGodine[selectedPeriodGodine].Slotovi[selectedSlot].DaniTjedna[selectedDan].Cjenik.map((item) => item.PocetakInt);

        arr.map((item, index) => {
            if (!newCjenikInt.includes(item)) {
                if (item != 24) newCjenik.push({ PocetakInt: item, Cijena: database.get("defaultCijena") || 0, PeriodGodineID: periodiGodine[selectedPeriodGodine].PeriodGodineID });
            } else {
                //let i = newCjenikInt.indexOf(item);
                //newCjenik.splice(i, 1);
            }
        });

        newCjenik.sort((a, b) => parseFloat(a.PocetakInt) - parseFloat(b.PocetakInt));

        if (newCjenik.length > 0 && newCjenik[0].PocetakInt != 0) {
            newCjenik.unshift({ PocetakInt: 0 });
        }

        setPeriodiGodine(newPeriodiGodine);
    }

    function getIndex(str) {
        let arr = str.split("-");
        return arr[arr.length - 1];
    }

    function removeRange(index) {
        let newPeriodiGodine = [...periodiGodine];
        let newCjenik = newPeriodiGodine[selectedPeriodGodine].Slotovi[selectedSlot].DaniTjedna[selectedDan].Cjenik;
        newCjenik.splice(index, 1);

        if ((newCjenik.length > 0 && newCjenik[0].PocetakInt != 0) || newCjenik.length == 0) {
            newCjenik.unshift({ PocetakInt: 0 });
        }

        setPeriodiGodine(newPeriodiGodine);
    }

    function spremiPeriode() {
        let data = { RotoGarazaID: database.get("rotoGarazaID"), PeriodiGodine: periodiGodine };

        setLoading(true);
        api.post("SavePeriodiGodine", data, (data) => {
            setPeriodiGodine(data.PeriodiGodine);
            setLoading(false);
        });
    }

    function spremiDisplayPoruke() {
        let id = database.get("rotoGarazaID");

        displayPoruke.forEach((x) => (x.RotoGarazaID = id));

        setLoading(true);
        api.post("DisplayPoruka/Add", displayPoruke, (data) => {
            setLoading(false);
        });
    }

    function spremiTimeoutKorake() {
        setTimeoutKoraciChanges(false);
        setLoading(true);
        api.post("TimeoutKorak/Add", timeoutKoraci, (data) => {
            setLoading(false);
        });
    }

    const uloge = database.get("uloge");
    const isAdmin = uloge && (uloge.includes(2) || uloge.includes(1));
    const uredjivanjeSlotova = uloge && uloge.includes(5);
    const uredjivanjeCjenika = uloge && uloge.includes(6);
    const uredjivanjePostavki = uloge && uloge.includes(7);

    function primjeniNaSveDane() {
        let newPeriodiGodine = [...periodiGodine];
        let newCjenik = newPeriodiGodine[selectedPeriodGodine].Slotovi[selectedSlot].DaniTjedna[selectedDan].Cjenik;

        newPeriodiGodine[selectedPeriodGodine].Slotovi[selectedSlot].DaniTjedna.map((item, index) => {
            item.Cjenik = [...newCjenik];
        });

        setPeriodiGodine(newPeriodiGodine);
    }

    function onCijenaChange(e, index) {
        let newPeriodiGodine = [...periodiGodine];
        let newCjenik = newPeriodiGodine[selectedPeriodGodine].Slotovi[selectedSlot].DaniTjedna[selectedDan].Cjenik;
        newCjenik[index].Cijena = e.target.value;
        setPeriodiGodine(newPeriodiGodine);
    }

    function setMessage(value) {
        let data = { RotoGarazaID: database.get("rotoGarazaID"), Message: value };

        if (!value) {
            alert("Molimo upišite poruku.");
            return;
        }

        api.post(
            "setmessage",
            data,
            (result) => {
                alert(result);
            },
            () => alert("Nepoznata greška.")
        );
    }

    function onDisplayPorukeChange(e, poruka) {
        debugger;
        let disabled = false;
        displayPoruke.forEach((x) => {
            if (x.Disabled) disabled = true;
        });

        if (disabled) {
            setDisplayPorukeChanges(false);
        } else {
            setDisplayPorukeChanges(true);
        }

        let data = [...displayPoruke];
        let value = e && e.target ? e.target.value : e;

        let displayPoruka = data.filter((x) => x.DisplayPorukaID == poruka.DisplayPorukaID)[0];
        if (displayPoruka) {
            if (e.target) {
                displayPoruka.Poruka = value;
                displayPoruka.Disabled = !validateFormat(value, displayPoruka.MaxBrojParametara);
            } else {
                displayPoruka.PrikaziPoruku = value;
            }

            setDisplayPoruke(data);
        }
    }

    function onTimeoutKoraciChange(e, korak, polje) {
        let value = e && e.target ? e.target.value : e;

        setTimeoutKoraciChanges(true);
        switch (polje) {
            case "TimeoutSekundi":
                if (!value || isNaN(value)) {
                    setTimeoutKoraciChanges(false);
                }
                break;
            case "CrveniSemafor":
                value = e ? 1 : 0;
                break;
            case "KorisnickaNotifikacija":
                value = e ? 1 : 0;
                break;
        }

        let data = [...timeoutKoraci];
        let timeoutKorak = data.filter((x) => x.TimeoutKorakID == korak.TimeoutKorakID)[0];
        if (timeoutKorak) {
            timeoutKorak[polje] = value;

            setTimeoutKoraci(data);
        }
    }

    function validateFormat(str, max) {
        let currentIndex = 0;
        let lastNumber = -1;

        let indexOf1 = 0;
        let indexOf2 = 0;

        while (indexOf1 != -1 || indexOf2 != -1) {
            indexOf1 = str.indexOf("{", currentIndex);
            indexOf2 = str.indexOf("}", currentIndex);

            if (indexOf1 == -1) {
                if (indexOf2 == -1) {
                    if (max == 0) {
                        return true;
                    }
                }
            }

            if (indexOf1 != -1) {
                if (indexOf2 == -1) {
                    setDisplayPorukeChanges(false);
                    return false;
                }
            }

            if (indexOf2 != -1) {
                if (indexOf1 == -1) {
                    setDisplayPorukeChanges(false);
                    return false;
                }
            }

            let num = str.substring(indexOf1 + 1, indexOf2);

            if (indexOf1 != -1) {
                if (indexOf2 != -1) {
                    if (num.trim() == "") {
                        setDisplayPorukeChanges(false);
                        return false;
                    } else {
                        if (isNaN(num)) {
                            setDisplayPorukeChanges(false);
                            return false;
                        }
                    }
                }
            }

            if (!isNaN(num) && num.trim() != "") {
                if (num != lastNumber) {
                    if (num - 1 != lastNumber) {
                        setDisplayPorukeChanges(false);
                        return false;
                    }
                }
            }

            if (num > max - 1) {
                setDisplayPorukeChanges(false);
                return false;
            }

            lastNumber = num;
            currentIndex = indexOf2 > indexOf1 ? indexOf2 + 1 : indexOf1 + 1;
        }

        return true;
    }

    // function validateFormat(str, max) {

    //     let currentIndex = -1;
    //     let lastNumber = -1;

    //     for (var i = 0; i < 1001; i++) {
    //         const placeholder = "{" + i + "}";
    //         const indexOf = str.indexOf(placeholder, currentIndex + 1);

    //         if (indexOf != -1) {
    //             const placeholderNumber = parseInt(placeholder.slice(1, -1));
    //             if (placeholderNumber > max - 1) {
    //                 return false;
    //             }
    //             if (lastNumber == -1 && placeholderNumber != 0) {
    //                 return false;
    //             }
    //             if (lastNumber != -1) {
    //                 if (lastNumber != placeholderNumber && lastNumber != placeholderNumber - 1) {
    //                     return false;
    //                 }
    //             }
    //             lastNumber = placeholderNumber;
    //         }
    //         currentIndex = indexOf;
    //     }

    //     return true;
    // }

    let lastIndexPoruka = 0;
    let lastPodIndexPoruka = 0;

    function getPorukaNumber(poruka) {
        if (poruka.RedoslijedZaPrikazWeb + "." + poruka.PodTipPorukeID == lastIndexPoruka) {
            lastPodIndexPoruka += 1;
        } else {
            lastPodIndexPoruka = 1;
        }
        lastIndexPoruka = poruka.RedoslijedZaPrikazWeb + "." + poruka.PodTipPorukeID;
        return poruka.RedoslijedZaPrikazWeb + "." + poruka.PodTipPorukeID + "." + lastPodIndexPoruka;
    }

    function onShowDisplayPoruka(value) {
        setShowDisplayPoruka(value);
        database.set("showDisplayPoruka", value);
    }
    //<AppstoreOutlined style={{color:"#a8abaa"}}></AppstoreOutlined>
    return loading ? (
        <Spinner></Spinner>
    ) : (
        <Row type="flex" style={{ minHeight: "80vh" }} className="fadeIn animated">
            {mode == "editGaraza" && <GarazaEdit data={garaza} onCancel={onCancel} onSave={onGarazaEdit} isAdmin={isAdmin}></GarazaEdit>}
            {mode == "default" && (
                <Col span={24}>
                    <button onClick={() => (window.location = "#/parkingTvrtka?parkingTvrtkaID=" + database.get("parkingTvrtkaID"))}>{"< Povratak"}</button>
                    <br></br>
                    <br></br>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <h1 style={{ marginBottom: 34 }} className="title">
                                        {garaza && garaza.Naziv}

                                        {isAdmin && (
                                            <EditOutlined
                                                style={{ cursor: "pointer", color: "gray", fontSize: 20 }}
                                                onClick={() => {
                                                    setMode("editGaraza");
                                                }}
                                            >
                                                Uredi
                                            </EditOutlined>
                                        )}
                                    </h1>
                                </td>
                                <td>{bojaSemaforaID == 3 && <div className="semaphore-red"></div>}</td>
                                <td> </td>
                            </tr>
                        </tbody>
                    </table>
                    <h1 className="subtitle">{garaza && garaza.Adresa}</h1>
                    <div className="divider"></div>
                    <div className="title-buttons">
                        {bojaSemaforaID != 3 && (
                            <Button
                                loading={loadingSemaphore}
                                onClick={() => {
                                    redSemaphoreOn();
                                }}
                            >
                                {!loadingSemaphore && <img style={{}} src={redsemaphore}></img>} &nbsp; Upali crveni semafor
                            </Button>
                        )}

                        {bojaSemaforaID == 3 && (
                            <Button
                                loading={loadingSemaphore}
                                onClick={() => {
                                    redSemaphoreOff();
                                }}
                            >
                                Ugasi crveni semafor
                            </Button>
                        )}

                        <Search
                            placeholder="Display poruka"
                            enterButton={
                                <Button style={{ color: "#3a3a3a" }} type="default">
                                    Postavi
                                </Button>
                            }
                            onSearch={setMessage}
                        />

                        {/* {isAdmin && (
                            <Button
                                onClick={() => {
                                    setMode("editGaraza");
                                }}
                            >
                                Uredi
                            </Button>
                        )} */}

                        {/* {isAdmin && (
                            <Button
                                onClick={() => {
                                    setDeaktiviratiGarazu(true);
                                }}
                            >
                                Deaktiviraj
                            </Button>
                        )} */}

                        {isAdmin && (
                            <Button
                                onClick={() => {
                                    const data = { RotoGarazaID: database.get("rotoGarazaID") };
                                    setLoading(true);
                                    api.post(
                                        "csprocessfinished",
                                        data,
                                        (result) => {
                                            alert(result);
                                            setLoading(false);
                                        },
                                        () => {
                                            alert("Greška!");
                                            setLoading(false);
                                        }
                                    );
                                }}
                            >
                                Reset sustava kamera
                            </Button>
                        )}
                    </div>
                    <br></br>
                    {displayPorukaIFrameURL && showDisplayPoruka ? <Iframe url={displayPorukaIFrameURL} styles={{ border: "none", overflowY: "hidden" }} width="100%" height="110px" id="" className="" display="block" position="relative" /> : null}

                    {showDisplayPoruka ? (
                        <div style={{ color: "#a7a7a7", textAlign: "center", paddingRight: 30, marginTop: -18, cursor: "pointer", position: "inherit" }} onClick={() => onShowDisplayPoruka(false)}>
                            Hide display
                        </div>
                    ) : (
                        <div style={{ color: "#a7a7a7", textAlign: "center", paddingRight: 30, marginTop: -18, cursor: "pointer", position: "inherit" }} onClick={() => onShowDisplayPoruka(true)}>
                            Show display
                        </div>
                    )}
                    <br></br>
                    <Tabs onChange={tabChange} activeKey={activeTab} defaultActiveKey={activeTab}>
                        <Tabs.TabPane tab="Status" key="1">
                            <table className="styled-table" style={{ textAlign: "left" }}>
                                <thead>
                                    <tr style={{ textAlign: "left" }}>
                                        <th>Status</th>

                                        <th>Opis</th>
                                        <th style={{ textAlign: "right" }}>{semaphoreStatus && semaphoreStatus.BojaSemaforaID == 2 && <Spin indicator={antIcon} />}</th>
                                        <th>Slot</th>
                                        <th>Proces</th>
                                        <th>Korak</th>
                                        <th>Registracija</th>
                                        <th>Početak</th>
                                        <th>Kraj</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {semaphoreStatus && semaphoreStatus.BojaSemaforaID == 1 && (
                                                <>
                                                    {" "}
                                                    <div className="semaphore-small-green"></div>
                                                </>
                                            )}
                                            {semaphoreStatus && semaphoreStatus.BojaSemaforaID == 2 && <div className="semaphore-small-yellow"></div>}
                                            {semaphoreStatus && semaphoreStatus.BojaSemaforaID == 3 && <div className="semaphore-small-red"></div>}
                                        </td>

                                        <td>
                                            {semaphoreStatus && (
                                                <>
                                                    {semaphoreStatus.BojaSemaforaOpis}. {semaphoreStatus.CrveniSemaforOpis}
                                                </>
                                            )}
                                        </td>
                                        <td>{currentParkingTrans && currentParkingTrans.SlotNaziv}</td>
                                        <td>{currentParkingTrans && currentParkingTrans.TipProcesaNaziv}</td>
                                        <td>{currentParkingTrans && currentParkingTrans.KorakNaziv}</td>
                                        <td>{currentParkingTrans && currentParkingTrans.Registracija}</td>
                                        <td>{currentParkingTrans && currentParkingTrans.Pocetak && Util.getDateFromDateTimeString(currentParkingTrans.RezervacijaPocetak) + " - " + Util.getTimeFromDateTimeString(currentParkingTrans.RezervacijaPocetak)}</td>
                                        <td>
                                            {currentParkingTrans && currentParkingTrans.Pocetak && Util.getDateFromDateTimeString(currentParkingTrans.RezervacijaKraj) + " - " + Util.getTimeFromDateTimeString(currentParkingTrans.RezervacijaKraj)}
                                            {bojaSemaforaID == 3 && <p>{Util.getDateFromDateTimeString(semaforVrijemeZadnjeIzmjene) + "  " + Util.getTimeFromDateTimeString(semaforVrijemeZadnjeIzmjene)}</p>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* {true && (
                                <table className="styled-table" style={{}}>
                                    <thead>
                                        <tr style={{ textAlign: "center" }}>
                                            <td>Slot</td>
                                            <td>Proces</td>
                                            <td>Korak</td>
                                            <td>Registracija</td>
                                            <td>Početak</td>
                                            <td>Kraj</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ textAlign: "center" }}>
                                            <th>{currentParkingTrans && currentParkingTrans.SlotNaziv}</th>
                                            <th>{currentParkingTrans && currentParkingTrans.TipProcesaNaziv}</th>
                                            <th>{currentParkingTrans && currentParkingTrans.KorakNaziv}</th>
                                            <th>{currentParkingTrans && currentParkingTrans.Registracija}</th>
                                            <td>{currentParkingTrans && currentParkingTrans.Pocetak && Util.getDateFromDateTimeString(currentParkingTrans.RezervacijaPocetak) + " - " + Util.getTimeFromDateTimeString(currentParkingTrans.RezervacijaPocetak)}</td>
                                            <td>{currentParkingTrans && currentParkingTrans.Pocetak && Util.getDateFromDateTimeString(currentParkingTrans.RezervacijaKraj) + " - " + Util.getTimeFromDateTimeString(currentParkingTrans.RezervacijaKraj)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )} */}
                            <table className="styled-table" style={{}}>
                                <thead>
                                    <tr style={{ textAlign: "center" }}>
                                        <th>Slot</th>
                                        <th>Status</th>
                                        <th>Tip</th>
                                        <th>Registracija</th>
                                        <th>Početak</th>
                                        <th>Kraj</th>
                                        <th>Plaćeno do</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slotOcuppancies.map((item, index) => {
                                        return (
                                            <tr key={"occ" + index} style={{ textAlign: "center" }}>
                                                {/* <td>{item.SlotOznaka + " " + item.SlotID}</td> */}
                                                <td>{item.SlotOznaka}</td>
                                                <td>{item.Registracija ? <img style={{ width: "30px" }} src={car}></img> : <p style={{ fontSize: "8px", color: "rgb(81 119 129)", fontWeight: "bold" }}> SLOBODNO</p>}</td>
                                                <td>{item.TipSlotNaziv}</td>
                                                <td>
                                                    <p style={{ fontSize: "14px", color: "rgb(81 119 129)", fontWeight: "bold" }}>{item.Registracija && item.Registracija.toString().toUpperCase()}</p>{" "}
                                                </td>
                                                <td>{item.Pocetak && Util.getDateFromDateTimeString(item.Pocetak) + " - " + Util.getTimeFromDateTimeString(item.Pocetak)}</td>
                                                <td>{item.Kraj && Util.getDateFromDateTimeString(item.Kraj) + " - " + Util.getTimeFromDateTimeString(item.Kraj)}</td>
                                                <td>{item.PlacenoDo && Util.getDateFromDateTimeString(item.PlacenoDo) + " - " + Util.getTimeFromDateTimeString(item.PlacenoDo)}</td>
                                                <td>
                                                    {item.Registracija && isAdmin ? (
                                                        <Tooltip title="Isprazni slot">
                                                            <LogoutOutlined
                                                                onClick={() => {
                                                                    setIspraznitiSlot(true);
                                                                    setSelectedSlotID(item.SlotID);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    <tr></tr>
                                </tbody>
                            </table>

                            {/* <Button
                                loading={loadingDetails}
                                onClick={() => {
                                    setLoadingDetails(true);
                                    updateGarageDetailedStatus();
                                }}
                            >
                                Refresh
                            </Button> */}
                        </Tabs.TabPane>

                        {(isAdmin || uredjivanjeSlotova) && (
                            <Tabs.TabPane tab="Slotovi" key="2">
                                <table>
                                    <tbody>
                                        {slotovi.length > 0 && (
                                            <tr>
                                                <td>
                                                    <div className="ant-input" style={{ width: 100, backgroundColor: "whiteSmoke" }}>
                                                        ID
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="ant-input" style={{ minWidth: 200, backgroundColor: "whiteSmoke" }}>
                                                        Naziv
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{ minWidth: 200, backgroundColor: "whiteSmoke" }} className="ant-input">
                                                        Tip
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {slotovi &&
                                            slotovi.map((slot, index) => {
                                                return (
                                                    <tr key={"rowslot" + index}>
                                                        <td>
                                                            <div className="ant-input">{slot.ParkingSlotID}</div>
                                                        </td>
                                                        <td>{garaza && garaza.InProduction ? <div className="ant-input">{slot.Oznaka}</div> : <Input value={slot.Oznaka} onChange={(e) => onOznakaChange(e, index)}></Input>}</td>
                                                        <td>
                                                            {garaza && garaza.InProduction ? (
                                                                <div className="ant-input">{tipSlots.filter((x) => x.TipSlotID == slot.TipSlotID)[0].Naziv} </div>
                                                            ) : (
                                                                <Select
                                                                    defaultValue={slot.TipSlotID}
                                                                    style={{ width: 220 }}
                                                                    onChange={(e) => {
                                                                        tipSlotChange(index, e);
                                                                    }}
                                                                    value={slot.TipSlotID}
                                                                >
                                                                    {tipSlots &&
                                                                        tipSlots.map((tip, index2) => {
                                                                            return (
                                                                                //ako je adhoc onda nemože biti sa punjenjems
                                                                                <Option key={"rowslot" + index + "select" + index2} value={tip.TipSlotID}>
                                                                                    {tip.Naziv}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                </Select>
                                                            )}
                                                        </td>
                                                        <td>{garaza && garaza.InProduction ? null : <button onClick={(e) => remove(index)}>Briši</button>}</td>
                                                    </tr>
                                                );
                                            })}

                                        {garaza && garaza.InProduction ? null : (
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td className="right">
                                                    {
                                                        <button style={{ marginTop: 20 }} onClick={add}>
                                                            Dodaj slot
                                                        </button>
                                                    }{" "}
                                                    {slotovi.length > 0 && (
                                                        <button disabled={!changes} style={{ marginTop: 20 }} onClick={save}>
                                                            Spremi izmjene
                                                        </button>
                                                    )}
                                                </td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Tabs.TabPane>
                        )}

                        {(isAdmin || uredjivanjeCjenika) && (
                            <Tabs.TabPane tab="Cjenik" key={3}>
                                <p className={"title-label"}>Periodi</p>
                                {dodavanjePerioda ? (
                                    <PeriodEdit onSave={onPeriodSave} onCancel={onPeriodCancel}></PeriodEdit>
                                ) : (
                                    <Tabs centered type="editable-card" onEdit={onEdit} onChange={onPeriodChange}>
                                        {periodiGodine &&
                                            periodiGodine.map((period, indexPeriod) => {
                                                return (
                                                    <Tabs.TabPane tab={period.Naziv} key={"p-" + indexPeriod}>
                                                        <p className="period-label">
                                                            {Util.getJustDateFromDateTimeString(period.Pocetak)} - {Util.getJustDateFromDateTimeString(period.Kraj)} <EditOutlined></EditOutlined>
                                                        </p>
                                                        <p className={"title-label"}>Vrste slotova</p>
                                                        <Tabs centered onChange={onSlotChange}>
                                                            {period.Slotovi.map((slot, indexSlot) => {
                                                                return (
                                                                    <Tabs.TabPane tab={slot.Naziv} key={"p-" + period.PeriodGodineID + "-" + indexSlot}>
                                                                        <p className={"title-label"}>Dani u tjednu</p>
                                                                        <Tabs centered onChange={onDanChange}>
                                                                            {slot.DaniTjedna.map((dan, indexDan) => {
                                                                                let values = dan.Cjenik.map((item) => item.PocetakInt);

                                                                                if (!values || values.length == 0) {
                                                                                    values = [0];
                                                                                    dan.Cjenik = [{ PocetakInt: 0, Cijena: database.get("defaultCijena") || 0, PeriodGodineID: periodiGodine[selectedPeriodGodine].PeriodGodineID }];
                                                                                }
                                                                                let marks = [];
                                                                                let arr = [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
                                                                                arr.map((item, indext) => {
                                                                                    marks.push({ value: indext, label: indext });
                                                                                });

                                                                                return (
                                                                                    <Tabs.TabPane tab={dan.Naziv} key={"p-" + period.PeriodGodineID + "-" + indexSlot + "-" + indexDan}>
                                                                                        <div style={{ textAlign: "center", width: "100%" }}>
                                                                                            <Slider style={{ width: "800px", cursor: "pointer" }} track={false} max={24} aria-labelledby="track-false-range-slider" value={values} marks={marks} onChange={onRangeChange} />
                                                                                            <br></br>
                                                                                            <table style={{ width: "800px", display: "inline-table" }} className="styled-table">
                                                                                                <tr>
                                                                                                    <th>Početak (h)</th>
                                                                                                    <th>Kraj (h)</th>
                                                                                                    <th>Cijena</th>
                                                                                                    <th></th>
                                                                                                </tr>

                                                                                                {dan.Cjenik.map((item, index) => {
                                                                                                    return (
                                                                                                        <tr key={"cjnk" + index}>
                                                                                                            <td>{values[index] || "00"}</td>
                                                                                                            <td>{values[index + 1] || "24"} </td>
                                                                                                            <td>
                                                                                                                <Input value={item.Cijena} onChange={(e) => onCijenaChange(e, index)} style={{ width: 120 }} addonAfter={"EUR"}></Input>
                                                                                                            </td>
                                                                                                            <td>{index > 0 && <DeleteOutlined style={{ cursor: "pointer" }} onClick={() => removeRange(index)}></DeleteOutlined>}</td>
                                                                                                        </tr>
                                                                                                    );
                                                                                                })}
                                                                                                <tr>
                                                                                                    <td colSpan={4} style={{ textAlign: "center" }}>
                                                                                                        <button onClick={primjeniNaSveDane}>Primjeni na sve dane</button> &nbsp;
                                                                                                        <button onClick={spremiPeriode}>Spremi izmjene</button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </Tabs.TabPane>
                                                                                );
                                                                            })}
                                                                        </Tabs>
                                                                    </Tabs.TabPane>
                                                                );
                                                            })}
                                                        </Tabs>
                                                    </Tabs.TabPane>
                                                );
                                            })}
                                    </Tabs>
                                )}
                            </Tabs.TabPane>
                        )}

                        {(isAdmin || uredjivanjePostavki) && (
                            <Tabs.TabPane tab="Postavke" key={4}>
                                <table style={{ width: "100%" }} class="tblpostavke" className="styled-table">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "45%" }}>
                                                Cam system end point <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td style={{ minWidth: "45%" }}>
                                                <Input id="CamSystemEndPoint" value={garaza && garaza.CamSystemEndPoint} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Cam system username <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td>
                                                <Input id="CamSystemUsername" value={garaza && garaza.CamSystemUsername} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Cam system password <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td>
                                                <Input id="CamSystemPassword" value={garaza && garaza.CamSystemPassword} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Cam system port <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td>
                                                <InputNumber id="CamSystemPort" value={garaza && garaza.CamSystemPort} onChange={(e) => onPostavkaChange(e, "CamSystemPort")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cam system timeout</td>
                                            <td>
                                                <InputNumber id="CamSystemTimeout" value={garaza && garaza.CamSystemTimeout} onChange={(e) => onPostavkaChange(e, "CamSystemTimeout")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                RP system end point <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td>
                                                <Input id="RPSystemEndPoint" value={garaza && garaza.RPSystemEndPoint} onChange={(e) => onPostavkaChange(e, "RPSystemEndPoint")}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                RP system username <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td>
                                                <Input id="RPSystemUsername" value={garaza && garaza.RPSystemUsername} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                RP system password <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td>
                                                <Input id="RPSystemPassword" value={garaza && garaza.RPSystemPassword} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                RP system port <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td>
                                                <InputNumber id="RPSystemPort" value={garaza && garaza.RPSystemPort} onChange={(e) => onPostavkaChange(e, "RPSystemPort")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                RP system timeout <Upitnik text="Cam system end point"></Upitnik>
                                            </td>
                                            <td>
                                                <InputNumber id="RPSystemTimeout" value={garaza && garaza.RPSystemTimeout} onChange={(e) => onPostavkaChange(e, "RPSystemTimeout")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cam system max broj pokušaja</td>
                                            <td>
                                                <InputNumber id="CamSystemMaxBrojPokusaja" value={garaza && garaza.CamSystemMaxBrojPokusaja} onChange={(e) => onPostavkaChange(e, "CamSystemMaxBrojPokusaja")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cam system odgoda novog pokušaja sekundi</td>
                                            <td>
                                                <InputNumber id="CamSystemOdgodaNovogPokusajaSekundi" value={garaza && garaza.CamSystemOdgodaNovogPokusajaSekundi} onChange={(e) => onPostavkaChange(e, "CamSystemOdgodaNovogPokusajaSekundi")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RP system max broj pokušaja</td>
                                            <td>
                                                <InputNumber id="RPSystemMaxBrojpokusaja" value={garaza && garaza.RPSystemMaxBrojPokusaja} onChange={(e) => onPostavkaChange(e, "RPSystemMaxBrojpokusaja")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RP system odgoda novog pokušaja sekundi</td>
                                            <td>
                                                <InputNumber id="RPSystemOdgodaNovogPokusajaSekundi" value={garaza && garaza.RPSystemOdgodaNovogPokusajaSekundi} onChange={(e) => onPostavkaChange(e, "RPSystemOdgodaNovogPokusajaSekundi")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Dodatno (nenaplatno) vrijeme za izlazak u minutama</td>
                                            <td>
                                                <InputNumber id="NenaplatniPeriodZaIzlazakMinuta" value={garaza && garaza.NenaplatniPeriodZaIzlazakMinuta} onChange={(e) => onPostavkaChange(e, "NenaplatniPeriodZaIzlazakMinuta")}></InputNumber>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tip jedinice mjere</td>
                                            <td>
                                                <Select
                                                    defaultValue={garaza && garaza.MinimalnaRezervacijaTipJediniceMjereID}
                                                    style={{ width: "100%" }}
                                                    onChange={(e) => {
                                                        onPostavkaChange(e, "MinimalnaRezervacijaTipJediniceMjereID");
                                                    }}
                                                    value={garaza && garaza.MinimalnaRezervacijaTipJediniceMjereID}
                                                >
                                                    {tipJediniceMjere &&
                                                        tipJediniceMjere.map((tip, index) => {
                                                            return (
                                                                //ako je adhoc onda nemože biti sa punjenjems
                                                                <Option key={"rowslottipj" + index} value={tip.TipJediniceMjereID}>
                                                                    {tip.Naziv}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Količina vremenskih jedinica za rezervacijski graf (eksperimentalno)</td>
                                            <td>
                                                <InputNumber id="RezervacijskiGrafDefaultnaKolicina" value={garaza && garaza.RezervacijskiGrafDefaultnaKolicina} onChange={(e) => onPostavkaChange(e, "RezervacijskiGrafDefaultnaKolicina")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>CamSystemCheckCameraRoute</td>
                                            <td>
                                                <Input id="CamSystemCheckCameraRoute" value={garaza && garaza.CamSystemCheckCameraRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CamSystemProcessFinishedRoute</td>
                                            <td>
                                                <Input id="CamSystemProcessFinishedRoute" value={garaza && garaza.CamSystemProcessFinishedRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CamSystemScanRoute</td>
                                            <td>
                                                <Input id="CamSystemScanRoute" value={garaza && garaza.CamSystemScanRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CamSystemUnparkingVehicleRoute</td>
                                            <td>
                                                <Input id="CamSystemUnparkingVehicleRoute" value={garaza && garaza.CamSystemUnparkingVehicleRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPDeliverVehicleRoute</td>
                                            <td>
                                                <Input id="RPDeliverVehicleRoute" value={garaza && garaza.RPDeliverVehicleRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPLowerBarrierRoute</td>
                                            <td>
                                                <Input id="RPLowerBarrierRoute" value={garaza && garaza.RPLowerBarrierRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPParkVehicleRoute</td>
                                            <td>
                                                <Input id="RPParkVehicleRoute" value={garaza && garaza.RPParkVehicleRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPPrepareSlotRoute</td>
                                            <td>
                                                <Input id="RPPrepareSlotRoute" value={garaza && garaza.RPPrepareSlotRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPRaiseBarrierRoute</td>
                                            <td>
                                                <Input id="RPRaiseBarrierRoute" value={garaza && garaza.RPRaiseBarrierRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPProcessFinishedRoute</td>
                                            <td>
                                                <Input id="RPProcessFinishedRoute" value={garaza && garaza.RPProcessFinishedRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPSetMessageRoute</td>
                                            <td>
                                                <Input id="RPSetMessageRoute" value={garaza && garaza.RPSetMessageRoute} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Lista e-mail adresa za obavijesti o problemima vezanim za sustav kamera</td>
                                            <td>
                                                <Input id="CamSystemEmailAlertList" value={garaza && garaza.CamSystemEmailAlertList} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPEmailAlertList</td>
                                            <td>
                                                <Input id="Lista e-mail adresa za obavijesti o problemima vezanim za roto sustav" value={garaza && garaza.RPEmailAlertList} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>UrlVehicleArrived</td>
                                            <td>
                                                <Input id="UrlVehicleArrived" value={garaza && garaza.UrlVehicleArrived} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>UrlSlotPrepared</td>
                                            <td>
                                                <Input id="UrlSlotPrepared" value={garaza && garaza.UrlSlotPrepared} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>UrlScanned</td>
                                            <td>
                                                <Input id="UrlScanned" value={garaza && garaza.UrlScanned} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>UrlVehicleParked</td>
                                            <td>
                                                <Input id="UrlVehicleParked" value={garaza && garaza.UrlVehicleParked} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>UrlVehicleDelivered</td>
                                            <td>
                                                <Input id="UrlVehicleDelivered" value={garaza && garaza.UrlVehicleDelivered} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>UrlBarrierLowered</td>
                                            <td>
                                                <Input id="UrlBarrierLowered" value={garaza && garaza.UrlBarrierLowered} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>UrlBarrierRaised</td>
                                            <td>
                                                <Input id="UrlBarrierRaised" value={garaza && garaza.UrlBarrierRaised} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Koliko minuta prije isteka rezervacije će stići podsjetnik o isteku</td>
                                            <td>
                                                <InputNumber id="ReservationReminderMinutesBefore" value={garaza && garaza.ReservationReminderMinutesBefore} onChange={(e) => onPostavkaChange(e, "ReservationReminderMinutesBefore")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Broj sekundi koji se šalje sustavu kamera kao vrijeme u kojem treba očitati vozilo na ulazu</td>
                                            <td>
                                                <InputNumber id="ScanInTimeoutSeconds" value={garaza && garaza.ScanInTimeoutSeconds} onChange={(e) => onPostavkaChange(e, "ScanInTimeoutSeconds")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Broj sekundi koji se šalje sustavu kamera kao vrijeme u kojem treba očitati vozilo na izlazu</td>
                                            <td>
                                                <InputNumber id="ScanOutTimeoutSeconds" value={garaza && garaza.ScanOutTimeoutSeconds} onChange={(e) => onPostavkaChange(e, "ScanOutTimeoutSeconds")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Broj minuta unutar kojeg ad-hoc dolazak prije rezervacije uzrokuje brisanje rezervacije</td>
                                            <td>
                                                <InputNumber min={0} id="ReservationAdHocCancelationPeriod" value={garaza && garaza.ReservationAdHocCancelationPeriod} onChange={(e) => onPostavkaChange(e, "ReservationAdHocCancelationPeriod")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Broj minuta koji se dodaje na početak perioda za potrebe rezervacije (ne naplaćuje se)</td>
                                            <td>
                                                <InputNumber min={0} id="ReservationInBuffer" value={garaza && garaza.ReservationInBuffer} onChange={(e) => onPostavkaChange(e, "ReservationInBuffer")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Broj minuta koji se dodaje na kraj perioda za potrebe rezervacije (ne naplaćuje se)</td>
                                            <td>
                                                <InputNumber min={0} id="ReservationOutBuffer" value={garaza && garaza.ReservationOutBuffer} onChange={(e) => onPostavkaChange(e, "ReservationOutBuffer")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Početno vrijeme za spuštanje rampe (minute) od postavljanja crvenog semafora</td>
                                            <td>
                                                <InputNumber min={0} id="RedSemaphoreServiceStartMinutes" value={garaza && garaza.RedSemaphoreServiceStartMinutes} onChange={(e) => onPostavkaChange(e, "RedSemaphoreServiceStartMinutes")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Krajnje vrijeme za spuštanje rampe (minute) od postavljanja crvenog semafora</td>
                                            <td>
                                                <InputNumber min={0} id="RedSemaphoreServiceEndMinutes" value={garaza && garaza.RedSemaphoreServiceEndMinutes} onChange={(e) => onPostavkaChange(e, "RedSemaphoreServiceEndMinutes")}></InputNumber>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Poruka za stanje mirovanja garaže (ispravno stanje)</td>
                                            <td>
                                                <Input id="IdleMessageNoError" value={garaza && garaza.IdleMessageNoError} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Poruka za stanje mirovanja garaže (greška)</td>
                                            <td>
                                                <Input id="MessagePermanentError" value={garaza && garaza.MessagePermanentError} onChange={onPostavkaChange}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Broj sekundi nakon postavljanja zelenog semafora za automatsko postavljanje poruke stanja (ispravnog) mirovanja</td>
                                            <td>
                                                <InputNumber min={0} id="IdleMessageAutomaticallySetSeconds" value={garaza && garaza.IdleMessageAutomaticallySetSeconds} onChange={(e) => onPostavkaChange(e, "IdleMessageAutomaticallySetSeconds")}></InputNumber>
                                            </td>
                                        </tr>

                                        <br></br>
                                        <br></br>

                                        <tr>
                                            <td>Da li se diže crveni semafor u slučaju da se ne očita vozilo prilikom ulaska</td>
                                            <td>
                                                <Switch checked={garaza && garaza.RedSemaphoreOnNoScannedIn} onChange={(e) => onPostavkaChange(e, "RedSemaphoreOnNoScannedIn")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Da li se diže crveni semafor u slučaju da se ne očita vozilo prilikom izlaska</td>
                                            <td>
                                                <Switch checked={garaza && garaza.RedSemaphoreOnNoScannedOut} onChange={(e) => onPostavkaChange(e, "RedSemaphoreOnNoScannedOut")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Omogući reset sustava kamera s weba</td>
                                            <td>
                                                <Switch checked={garaza && garaza.SetCSProcessFinishedFromWeb} onChange={(e) => onPostavkaChange(e, "SetCSProcessFinishedFromWeb")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Digni crveni semafor na dosegu max. broj pokušaja komunikacije sa sustavom kamera</td>
                                            <td>
                                                <Switch checked={garaza && garaza.CamSystemCrveniSemaforOnMaxBrojPokusaja} onChange={(e) => onPostavkaChange(e, "CamSystemCrveniSemaforOnMaxBrojPokusaja")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Digni crveni semafor na dosegu max. broja pokušaja komunikacije s roto sustavom</td>
                                            <td>
                                                <Switch checked={garaza && garaza.RPSystemCrveniSemaforOnMaxBrojPokusaja} onChange={(e) => onPostavkaChange(e, "RPSystemCrveniSemaforOnMaxBrojPokusaja")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Omogući slanje poruka s web sučelja</td>
                                            <td>
                                                <Switch checked={garaza && garaza.SetMessagageFromWeb} onChange={(e) => onPostavkaChange(e, "SetMessagageFromWeb")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Obriši zauzeće ukoliko dođe vozilo iste registracije</td>
                                            <td>
                                                <Switch checked={garaza && garaza.CancelPreviousOccupancyWithSamePlateAsJustArrived} onChange={(e) => onPostavkaChange(e, "CancelPreviousOccupancyWithSamePlateAsJustArrived")} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button
                                    disabled={!postavkeChanges}
                                    style={{ marginTop: 20 }}
                                    onClick={() => {
                                        saveGaraza(garaza);
                                    }}
                                >
                                    Spremi izmjene
                                </button>
                            </Tabs.TabPane>
                        )}

                        {(isAdmin || uredjivanjePostavki) && (
                            <Tabs.TabPane tab="Display poruke" key={5}>
                                <table style={{ width: "100%" }} className="styled-table">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "50%", padding: "10px", background: "#f9f9f9" }}>Korak</td>
                                            <td style={{ minWidth: "40%", padding: "10px", background: "#f9f9f9" }}>Poruka</td>
                                            <td style={{ minWidth: "10%", textAlign: "center", padding: "10px", background: "#f9f9f9" }}>Prikaži poruku</td>
                                        </tr>

                                        {displayPoruke &&
                                            displayPoruke.map((poruka, index) => {
                                                return (
                                                    <>
                                                        <tr style={{ border: "5px solid white" }}></tr>
                                                        <tr style={{ marginTop: 5 }}>
                                                            <td style={{ padding: "10px" }}>
                                                                {getPorukaNumber(poruka)} - {poruka.NazivWeb}
                                                                <div style={{ paddingLeft: 0, fontSize: 10, color: "#3c8e9f" }}>{poruka.TipDisplayPorukeID == 0 ? "Poruka o upješnom postupku ili informativna poruka" : "Poruka o neuspješnom postupku"}</div>
                                                            </td>
                                                            <td style={{ padding: "10px" }}>
                                                                <Input id="CamSystemUsername" value={poruka.Poruka} onChange={(e) => onDisplayPorukeChange(e, poruka)}></Input>
                                                                <div style={{ paddingLeft: 10, fontSize: 10, color: "#3c8e9f" }}>{poruka.OpisParametara + ", Max broj parametara: " + poruka.MaxBrojParametara}</div>

                                                                {poruka.Disabled ? <div style={{ paddingLeft: 10, fontSize: 10, color: "red" }}>Neispravan unos</div> : null}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <Switch checked={poruka.PrikaziPoruku} onChange={(e) => onDisplayPorukeChange(e, poruka)} />
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <button
                                    disabled={!displayPorukeChanges}
                                    style={{ marginTop: 20 }}
                                    onClick={() => {
                                        spremiDisplayPoruke();
                                    }}
                                >
                                    Spremi izmjene
                                </button>
                            </Tabs.TabPane>
                        )}

                        {(isAdmin || uredjivanjePostavki) && (
                            <Tabs.TabPane tab="Time out korak" key={6}>
                                <table style={{ width: "100%" }} className="styled-table">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "50%", padding: "10px", background: "#f9f9f9" }}>Korak</td>
                                            <td style={{ width: "100px", minWidth: "40%", padding: "10px", background: "#f9f9f9" }}>Timeout sekundi</td>
                                            <td style={{ minWidth: "10%", textAlign: "center", padding: "10px", background: "#f9f9f9" }}>Crveni semafor</td>
                                            <td style={{ minWidth: "10%", textAlign: "center", padding: "10px", background: "#f9f9f9" }}>Korisnička notifikacija</td>
                                        </tr>

                                        {timeoutKoraci &&
                                            timeoutKoraci.map((korak, index) => {
                                                return (
                                                    <>
                                                        <tr style={{ border: "5px solid white" }}></tr>
                                                        <tr style={{ marginTop: 5 }}>
                                                            <td style={{ padding: "10px" }}>{korak.KorakNazivWeb}</td>
                                                            <td style={{ padding: "10px" }}>
                                                                <InputNumber value={korak.TimeoutSekundi} onChange={(e) => onTimeoutKoraciChange(e, korak, "TimeoutSekundi")}></InputNumber>
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <Switch checked={korak.CrveniSemafor} onChange={(e) => onTimeoutKoraciChange(e, korak, "CrveniSemafor")} />
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <Switch checked={korak.KorisnickaNotifikacija} onChange={(e) => onTimeoutKoraciChange(e, korak, "KorisnickaNotifikacija")} />
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <button
                                    disabled={!timeoutKoraciChanges}
                                    style={{ marginTop: 20 }}
                                    onClick={() => {
                                        spremiTimeoutKorake();
                                    }}
                                >
                                    Spremi izmjene
                                </button>
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                    <Dialog open={ispraznitiSlot} onClose={handleCloseIspraznitiSlot} maxWidth={"sm"} fullWidth aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Upozorenje!"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Manualnim pražnjenjem slota može doći do netočnosti podataka sa realnim stanjem. Molimo upišite razlog brisanja slota.</DialogContentText>
                            <TextArea onChange={(e) => setRazlogPraznjenjaSlota(e.target.value)} rows={4} placeholder="Razlog" maxLength={255} />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleCloseIspraznitiSlot(0);
                                }}
                            >
                                ODUSTANI
                            </Button>
                            <Button
                                disabled={!!!razlogPraznjenjaSlota}
                                onClick={() => {
                                    handleCloseIspraznitiSlot(1);
                                }}
                                autoFocus
                            >
                                ISPRAZNI SLOT
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Col>
            )}
        </Row>
    );
}

export default Garaza;
