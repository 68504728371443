import React, { useState, useContext, useEffect } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import api from "../util/api";
import { Button, Row, Col, Input } from "antd";
import Util from "../util/util";
import database from "../util/database";
import Spinner from "../components/spinner";
import { Select } from "antd";
import { Checkbox } from "antd";

const { Option } = Select;

function OperaterEdit(props) {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [naziv, setNaziv] = useState();
    const [adresa, setAdresa] = useState();
    const [mjestoID, setMjestoID] = useState();

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [isAdmin, setIsAdmin] = useState();
    const [uredjivanjeSlotova, setUredjivanjeSlotova] = useState();
    const [uredjivanjeCjenika, setUredjivanjeCjenika] = useState();
    const [uredjivanjePostavki, setUredjivanjePostavki] = useState();

    const [garaze, setGaraze] = useState([]);
    const [apiRotoGarazaID, setApiRotoGarazaID] = useState();
    const [slotovi, setSlotovi] = useState([]);
    const [tvrtka, setTvrtka] = useState();
    const [tipParkingSlots, setTipParkingSlots] = useState(null);
    const [changes, setChanges] = useState(null);
    const [mjesta, setMjesta] = useState([]);

    useEffect(() => {
        if (!loaded) {
            const mjestas = database.get("mjesta");
            setMjesta(mjestas);

            if (props.data) {
                if (props.data.Username) setUsername(props.data.Username);
                if (props.data.Password) setPassword(props.data.Password);
                if (props.data.ApiRotoGarazaID) setApiRotoGarazaID(props.data.ApiRotoGarazaID);
                if (props.data.IsAdmin) setIsAdmin(props.data.IsAdmin);
                if (props.garaze) setGaraze(props.garaze);

                if (props.data.Uloge) {
                    if (props.data.Uloge.includes(2)) setIsAdmin(true);

                    if (props.data.Uloge.includes(5)) setUredjivanjeSlotova(true);

                    if (props.data.Uloge.includes(6)) setUredjivanjeCjenika(true);

                    if (props.data.Uloge.includes(7)) setUredjivanjePostavki(true);

                    // if (props.data.MjestoID) setMjestoID(props.data.MjestoID);
                }
            }
        }
        setLoaded(true);
    });

    function save() {
        if (props.onSave) {
            let uloge = [];

            if (isAdmin) uloge.push(2);

            if (uredjivanjeSlotova) uloge.push(5);

            if (uredjivanjeCjenika) uloge.push(6);

            if (uredjivanjePostavki) uloge.push(7);

            let data = { Username: username, Password: password, IsAdmin: isAdmin, ApiRotoGarazaID: apiRotoGarazaID, Uloge: uloge };

            props.onSave(data);
        }
    }

    function onUsernameChange(e) {
        setChanges(true);
        setUsername(e.target.value);
    }

    function onPasswordChange(e) {
        setChanges(true);
        setPassword(e.target.value);
    }

    function onGarazaChange(data) {
        console.log(data);
        setApiRotoGarazaID(data);
    }

    return loading ? (
        <Spinner></Spinner>
    ) : (
        <Row type="flex" style={{ minHeight: "80vh" }} className="fadeIn animated">
            <Col span={24}>
                <button
                    onClick={() => {
                        if (props.onCancel) props.onCancel();
                    }}
                >
                    {"< Povratak"}
                </button>
                <br></br>
                <br></br>
                <h1 className="title">{props.data ? "Uredi operatera" : "Novi operater"}</h1>
                <h1 className="subtitle"></h1>
                <div className="divider"></div>
                <br></br>
                <br></br>
                <br></br>
                <table>
                    <tbody>
                        <tr>
                            <td>Username</td>
                            <td>Password</td>
                            <td>Api roto garaža</td>
                        </tr>

                        <tr>
                            <td>
                                <Input value={username} disabled={props.data.OperaterID != 0} onChange={onUsernameChange}></Input>
                            </td>
                            <td>
                                <Input value={password} onChange={onPasswordChange}></Input>
                            </td>
                            <td>
                                <Select
                                    value={apiRotoGarazaID}
                                    style={{ width: 220 }}
                                    onChange={(e) => {
                                        onGarazaChange(e);
                                    }}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    <Option key={"rowslot0"} value={0}>
                                        ------
                                    </Option>
                                    {garaze.map((garaza, index2) => {
                                        return (
                                            <Option key={"rowslot2" + index2 + "select2" + index2} value={garaza.RotoGarazaID}>
                                                {garaza.Naziv}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </td>
                            <td>
                                <button
                                    disabled={!(username && password)}
                                    onClick={() => {
                                        save();
                                    }}
                                >
                                    Spremi
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ marginTop: "50px" }} className="styled-table">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "left" }} colSpan="4">
                                Korisnička prava
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Administrator</td>
                            <td>
                                <Checkbox checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)}></Checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td>Uređivanje slotova</td>
                            <td>
                                <Checkbox checked={uredjivanjeSlotova} onChange={(e) => setUredjivanjeSlotova(e.target.checked)}></Checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td>Uređivanje cjenika</td>
                            <td>
                                <Checkbox checked={uredjivanjeCjenika} onChange={(e) => setUredjivanjeCjenika(e.target.checked)}></Checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td>Uređivanje postavki</td>
                            <td>
                                <Checkbox checked={uredjivanjePostavki} onChange={(e) => setUredjivanjePostavki(e.target.checked)}></Checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default OperaterEdit;
