import React, { useState, useContext, useEffect } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import api from "../util/api";
import { Button, Row, Col, Input } from "antd";
import Util from "../util/util";
import database from "../util/database";
import Spinner from "../components/spinner";
import { Select } from "antd";

const { Option } = Select;

function Test(props) {
    const [registration, setRegistration] = useState("ZG2667GT");
    const [loading, setLoading] = useState(false);
    const [garageID, setGarageID] = useState("18");

    function park() {
         setLoading(true);
         https://markoja.ipt.hr/api/vehiclearrived?registracija=ZG2667GT&rotogarazaID=18
         api.get("vehiclearrived?registracija=" + registration + "&rotogarazaID=" + garageID, (data) => {
            setTimeout(() => {setLoading(false)}, 2000);
            
         }, (data) => {
            setLoading(false);
            alert("Greška!");
         });

    }

    return loading ? (
        <Spinner></Spinner>
    ) : (
        <Row type="flex" style={{ minHeight: "80vh" }} className="fadeIn animated">
            <Col span={24}>
                <button
                    onClick={() => {
                        window.location = "#/home";
                    }}
                >
                    {"< Povratak"}
                </button>
                <br></br>
                <br></br>
                <h1 className="title">Parkiraj vozilo</h1>
                <h1 className="subtitle"></h1>
                <div className="divider"></div>
                <br></br>
                <br></br>
                <br></br>
                <table>
                    <tbody>
                        <tr>
                            <td>Registracija</td>
                            <td>Roto garaža ID</td>
                        </tr>               
                        <tr>
                            <td>
                                <Input value={registration} onChange={(e)=> setRegistration(e.target.value)}></Input>
                            </td>
                            <td>
                                <Input value={garageID} onChange={(e)=> setGarageID(e.target.value)}></Input>
                            </td>
                            <td>
                                <button
                                    
                                    onClick={() => {
                                        park();
                                    }}
                                >
                                    Parkiraj
                                </button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default Test;
