import React from "react";
import { SettingOutlined } from "@ant-design/icons";

class Spinner extends React.Component {
    render() {
        return (
            <div className="spinner fadeIn animated">
                <div  className="spinner-icon">
                    {/* <LineIcon size="sm" name="spiner-solid lni-is-spinning" /> */}
                    <SettingOutlined></SettingOutlined>
                </div>
            </div>
        );
    }
}
export default Spinner;
