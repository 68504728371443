import React, { useState, useContext, useEffect } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import api from "../util/api";
import { Button, Row, Col, Input } from "antd";
import Util from "../util/util";
import database from "../util/database";
import Spinner from "../components/spinner";
import { Select } from "antd";

const { Option } = Select;

function ParkingTvrtkaEdit(props) {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [naziv, setNaziv] = useState();
    const [adresa, setAdresa] = useState();
    const [mjestoID, setMjestoID] = useState();

    const [slotovi, setSlotovi] = useState([]);
    const [tvrtka, setTvrtka] = useState();
    const [tipParkingSlots, setTipParkingSlots] = useState(null);
    const [changes, setChanges] = useState(null);
    const [mjesta, setMjesta] = useState([]);

    useEffect(() => {
        if (!loaded) {

            const mjestas = database.get("mjesta");
            setMjesta(mjestas);

            if (props.data) {
                if (props.data.Naziv) setNaziv(props.data.Naziv);
                if (props.data.Adresa) setAdresa(props.data.Adresa);
                if (props.data.MjestoID) setMjestoID(props.data.MjestoID);
            }

        }
        setLoaded(true);
    });

    function save() {
        if (props.onSave) {
            let data = { Naziv: naziv, MjestoID: mjestoID, Adresa: adresa };
            props.onSave(data);
        }
    }

    function onNazivChange(e) {
        setChanges(true);
        setNaziv(e.target.value);
    }

    function onAdresaChange(e) {
        setChanges(true);
        setAdresa(e.target.value);
    }
    function onMjestoChange(e) {
        setChanges(true);
        setMjestoID(e);
    }

    return loading ? (
        <Spinner></Spinner>
    ) : (
        <Row type="flex" style={{ minHeight: "80vh" }} className="fadeIn animated">
            <Col span={24}>
                <button
                    onClick={() => {
                        if (props.onCancel) props.onCancel();
                    }}
                >
                    {"< Povratak"}
                </button>
                <br></br>
                <br></br>
                <h1 className="title">{props.data ? "Uredi tvrtku" : "Nova tvtrka"}</h1>
                <h1 className="subtitle"></h1>
                <div className="divider"></div>
                <br></br>
                <br></br>
                <br></br>
                <table>
                    <tbody>
                        <tr>
                            <td>Naziv</td>
                            <td>Adresa</td>
                            <td>Mjesto</td>
                        </tr>


                        
                        <tr>
                            <td>
                                <Input value={naziv} onChange={onNazivChange}></Input>
                            </td>
                            <td>
                                <Input value={adresa} onChange={onAdresaChange}></Input>
                            </td>
                            <td>
                                <Select
                                    value={mjestoID}
                                    style={{ width: 220 }}
                                    onChange={(e) => {
                                        onMjestoChange(e);
                                    }}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {mjesta.map((mjesto, index2) => {
                                        return (
                                            <Option key={"rowslot2" + index2 + "select2" + index2} value={mjesto.MjestoID}>
                                                {mjesto.PBR + " " + mjesto.MjestoNaziv}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </td>
                            <td>
                                <button
                                    disabled={!(naziv && adresa && mjestoID)}
                                    onClick={() => {
                                        save();
                                    }}
                                >
                                    Spremi
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default ParkingTvrtkaEdit;
