import React, { useState, useContext, useEffect } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import api from "../util/api";
import { Button, Row, Col, Input } from "antd";
import Util from "../util/util";
import database from "../util/database";
import Spinner from "../components/spinner";
import { Select } from "antd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const { Option } = Select;

function GarazaEdit(props) {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [naziv, setNaziv] = useState();
    const [adresa, setAdresa] = useState();
    const [mjestoID, setMjestoID] = useState();
    const [slotovi, setSlotovi] = useState([]);
    const [tvrtka, setTvrtka] = useState();
    const [tipParkingSlots, setTipParkingSlots] = useState(null);
    const [changes, setChanges] = useState(null);
    const [mjesta, setMjesta] = useState([]);
    const [deaktiviratiGarazu, setDeaktiviratiGarazu] = React.useState(false);

    useEffect(() => {
        if (!loaded) {
            const mjestas = database.get("mjesta");
            setMjesta(mjestas);

            if (props.data) {
                if (props.data.Naziv) setNaziv(props.data.Naziv);
                if (props.data.MjestoID) setMjestoID(props.data.MjestoID);
                // if (props.data.MjestoID) setMjestoID(props.data.MjestoID);
            }
        }
        setLoaded(true);
    });

    function save() {
        if (props.onSave) {
            let data = { Naziv: naziv, MjestoID: mjestoID, Adresa: adresa };
            props.onSave(data);
        }
    }

    function onNazivChange(e) {
        setChanges(true);
        setNaziv(e.target.value);
    }

    function onMjestoChange(e) {
        let m = mjesta.filter((x) => x.MjestoID == e)[0];
        let adr = m.PBR + " " + m.MjestoNaziv;

        setChanges(true);
        setAdresa(adr);
        setMjestoID(e);
    }

    const handleClose = (ok) => {
        setDeaktiviratiGarazu(false);
        if (ok == 1) {
            api.post("DeactivateRotoGaraza", garaza, (data) => {
                window.location = "#/parkingTvrtka?parkingTvrtkaID=" + database.get("parkingTvrtkaID");
            });
        }
    };

    return loading ? (
        <Spinner></Spinner>
    ) : (
        <Row type="flex" style={{ minHeight: "80vh" }} className="fadeIn animated">
            <Col span={24}>
                <Dialog open={deaktiviratiGarazu} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Upozorenje!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Deaktivacijom garaže, svi podaci o garaži biti će nedostupni. </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleClose(0);
                            }}
                        >
                            ODUSTANI
                        </Button>
                        <Button
                            onClick={() => {
                                handleClose(1);
                            }}
                            autoFocus
                        >
                            RAZUMIJEM
                        </Button>
                    </DialogActions>
                </Dialog>
                <button
                    onClick={() => {
                        if (props.onCancel) props.onCancel();
                    }}
                >
                    {"< Povratak"}
                </button>
                <br></br>
                <br></br>
                <h1 className="title">{props.data ? "Uredi garažu" : "Nova garaža"}</h1>

                <h1 className="subtitle"></h1>
                <div className="divider"></div>
                <br></br>
                <br></br>
                <br></br>
                <table>
                    <tbody>
                        <tr>
                            <td>Naziv</td>
                            <td>Mjesto</td>
                            {/* <td>Mjesto</td> */}
                        </tr>

                        <tr>
                            <td>
                                <Input value={naziv} onChange={onNazivChange}></Input>
                            </td>

                            <td>
                                <Select
                                    value={mjestoID}
                                    style={{ width: 220 }}
                                    onChange={(e) => {
                                        onMjestoChange(e);
                                    }}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {mjesta.map((mjesto, index2) => {
                                        return (
                                            <Option key={"rowslot2" + index2 + "select2" + index2} value={mjesto.MjestoID}>
                                                {mjesto.PBR + " " + mjesto.MjestoNaziv}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </td>
                            <td>
                                <Button
                                    style={{ width: "100%" }}
                                    disabled={!(naziv && mjestoID)}
                                    onClick={() => {
                                        save();
                                    }}
                                >
                                    Spremi
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {props.isAdmin && (
                    <Button
                        type="ghost"
                        style={{ marginTop: "25px", float:"right", color:"red" }}
                        onClick={() => {
                            setDeaktiviratiGarazu(true);
                        }}
                    >
                        Deaktiviraj garažu
                    </Button>
                )}
            </Col>
        </Row>
    );
}

export default GarazaEdit;
