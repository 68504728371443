 
import api from "./api";
import languages from "./languages";
import { useState, useRef } from "react";

import GlobalContext from "../components/globalContext";
import database from "./database";

export const util = {
    confirm,
    alert,
    getParametersFromUrl,
    getCurrentPosition,
    getPlatform,
    translate,
    getCookie,
    setCookie,
    setLanguage,
    isMobileDevice,
    unixToDateTime,
    getSecondsBetweenDates,
    getSecondsToTime,
    getDateFromDateTimeString,
    getTimeFromDateTimeString,
 
    sanitizePlateNumber,
    openTemplate,
    getDuration,
    htmlDecode,
    openWSPayForm,
    init,
    throwExcMessage,
    getExcMessage,
    getPermissionForiOSNotifications,
    getRoles,
    logout,
    getJustDateFromDateTimeString
};

function getRoles(){
    return [];
}

function getTimeFromDateTimeString(date) {
    date = new Date(date);
    return date.toLocaleTimeString();
}

function getDateFromDateTimeString(date) {
    date = new Date(date);
    let todayDate = new Date();
    let tomorowDate = new Date();
    tomorowDate.setDate(tomorowDate.getDate() + 1);

    let today = todayDate.getDate() + "." + (todayDate.getMonth() + 1) + "." + todayDate.getFullYear();
    let tomorow = tomorowDate.getDate() + "." + (tomorowDate.getMonth() + 1) + "." + tomorowDate.getFullYear();
    let newDate = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();

    // if (today == newDate) return translate("today");

    // if (tomorow == newDate) return translate("tomorrow");

    return newDate;
}

function getJustDateFromDateTimeString(date) {
    date = new Date(date);
    let todayDate = new Date();
    let tomorowDate = new Date();
    tomorowDate.setDate(tomorowDate.getDate() + 1);

    let today = todayDate.getDate() + "." + (todayDate.getMonth() + 1) + "." + todayDate.getFullYear();
    let tomorow = tomorowDate.getDate() + "." + (tomorowDate.getMonth() + 1) + "." + tomorowDate.getFullYear();
    let newDate = date.getDate() + "." + (date.getMonth() + 1) + ".";

    return newDate;
}

function getSecondsToTime(seconds, dana) {
    let measuredTime = new Date(null);
    measuredTime.setSeconds(seconds); // specify value of SECONDS
    var isoTime = measuredTime.toISOString();

    var datumDo = new Date(isoTime.substr(0, 10));
    var datumOd = new Date("1970-01-01");
    var days = getDaysBetweenDates(datumOd, datumDo);

    var rtrn = isoTime.substr(11, 8);

    if (days >= 0) {
        rtrn = days + " " + dana + ", " + rtrn;
    }

    return rtrn;
}

function getSecondsBetweenDates(date1, date2) {
    let dif = date1.getTime() - date2.getTime();
    let Seconds_from_T1_to_T2 = dif / 1000;
    return Math.abs(Seconds_from_T1_to_T2);
}

function getDaysBetweenDates(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

function unixToDateTime(data) {
    let unix_timestamp = data;
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unix_timestamp * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

    return formattedTime;
}

function isMobileDevice() {
    if (window.hasOwnProperty("cordova")) {
        return true;
    }

    // Or

    if (typeof cordova == "object") {
        return true;
    }
    // Or

    if (!!window.cordova) {
        return true;
    }

    return false;
}


function logout(){
    database.remove("user");
    window.location = "#/login";
    window.location.reload();
}

function confirm(text, title, callback, buttons){
    if (window.navigator.notification) {
        
        if(!title){
            title = translate('naslov');
        }

        if(!buttons){
            buttons = [translate('odustani'), translate('ok')]; 
        }

        window.navigator.notification.confirm(text || " ", callback, title, buttons);
    } else {
        if(window.confirm(text)){
            callback(2);
        }
        else{
            callback(1);
        }
    }
}

function alert(text, title, buttonName, callback) {
    if (window.navigator.notification) {
        if(callback){
            window.navigator.notification.alert(text || " ", () => callback(gMessages), title || translate('naslov'), buttonName || "OK");
        }
        else{
            window.navigator.notification.alert(text || " ", null, title || translate('naslov'), buttonName || "OK");
        }
    } else {
        window.alert(text);
        if(callback){
            callback(gMessages);
        }
    }
}

function alertWithCallback(text, title, buttonName, callback){
    alert(text, title, buttonName);
    callback(gMessages);
}

function getExcMessage(data){
    if(!data){
        return util.translate('nepoznata-greska');
    }
    else{
        if(data.Message){
            return data.Message;
        }
        else if(data.responseJSON && data.responseJSON.ExceptionMessage){
            return data.responseJSON.ExceptionMessage;
        }
        else if(data.statusText){
            return data.statusText;
        }
        else{
            if(typeof(data) === "string"){
                return data;
            }
            else{
                return util.translate('nepoznata-greska');
            }
        }
    }    
}

function throwExcMessage(data){

    alert(translate('greska') + ": " + getExcMessage(data));

}

function getParametersFromUrl(url) {
    let params = {};
    (url + "?")
        .split("?")[1]
        .split("&")
        .forEach(function (pair) {
            pair = (pair + "=").split("=").map(decodeURIComponent);
            if (pair[0].length) {
                params[pair[0]] = pair[1];
            }
        });
    return params;
}

var getCurrentPositionSucessOut;
var getCurrentPositionErrorOut;

function getCurrentPosition(success, error) {
    getCurrentPositionSucessOut = success;
    getCurrentPositionErrorOut = error;

    if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(getCurrentPositionSuccess, getCurrentPositionError, { enableHighAccuracy: false, timeout: 5000 });
    } else {
        error(null);
    }
}

function getCurrentPositionSuccess(data) {
    getCurrentPositionSucessOut({ latitude: data.coords.latitude, longitude: data.coords.longitude });
}

function getCurrentPositionError(data) {
    getCurrentPositionErrorOut(data);
}

function getPlatform() {
    if (window.device) {
        return window.device.platform.toLowerCase();
    }
    return "Browser";
}



function translate(text) {
    let lan = localStorage.getItem("lang");
    if (!lan) {
        //setCookie("lang", "hr");
        localStorage.setItem("lang", "EN");
        localStorage.setItem("langID", "2");
        lan = "EN";
    }

    let a = api.host;
    let b = languages.get(lan,text);
    if (languages.get(lan) && languages.get(lan,text)) {
        return languages.get(lan,text);
    } else {
        return "Nema prijevoda, key: " + text + ", jezik: " + lan;
    }
}

function setLanguage(lang) {
    //setCookie("lang", lang);
    localStorage.setItem("lang", lang.shortName);
    localStorage.setItem("langID", lang.languageId);
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cookieName, value, expirationDate) {
    if (expirationDate) {
        document.cookie = cookieName + "=" + value + "; path=/; expires=" + expirationDate.toUTCString();
    } else {
        document.cookie = cookieName + "=" + value + "; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    }
}


function sanitizePlateNumber(plateNumber) {
    // the characters i'm looking for in a string:
    var charList = ['"', "<", ">", "?", "!", ")", "(", "/", "$", "&", "%", "#", "*", "+", "=", "_", ";", ",", ".", ":", "-", " ", "Ą", "ą", "Ć", "ć", "Č", "ć", "Ę", "ę", "È", "è", "Ł", "ł", "Ó", "ó", "Ś", "ś", "Š", "š", "Ź", "ź", "Ż", "ź", "Ž", "ž", "Đ", "đ", "Ó", "ó", "Ö", "ö", "Ü", "ü", "Ä", "ä", "Ë", "ë", "Ï", "ï", "À", "à", "Á", "á", "Â", "â", "Ã", "ã", "Å", "å", "Æ", "æ", "Ç", "ç", "É", "é", "Ê", "ê", "Ì", "ì", "Í", "í", "Î", "î", "Ñ", "ñ", "Ò", "ò", "Ó", "ó", "Ô", "ô", "Õ", "õ", "Ø", "ø", "Ù", "ù", "Ú", "ú", "Û", "û", "Ü", "ü"];

    // the characters i'd like to replace them with:
    var replaceList = [" ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", "A", "a", "C", "c", "C", "c", "E", "e", "E", "e", "L", "l", "O", "o", "S", "s", "S", "s", "Z", "z", "Z", "z", "Z", "z", "D", "d", "O", "o", "O", "o", "U", "u", "A", "a", "E", "e", "I", "i", "A", "a", "A", "a", "A", "a", "A", "a", "A", "a", "E", "e", "C", "c", "E", "e", "E", "e", "I", "i", "I", "i", "I", "i", "N", "n", "O", "o", "O", "o", "O", "o", "O", "o", "O", "o", "U", "u", "U", "u", "U", "u", "U", "u"];

    var sanitizedPlateNumber = plateNumber;
    var limit = sanitizedPlateNumber.length;
    for (let i = 0; i < limit; i++) {
        for (var j in charList) {
            if (sanitizedPlateNumber.charAt(i) === charList[j]) {
                //našao sam karakter u registarskoj oznaci na poziciji i, koji odgovara problematičnom karakteru na poziciji j
                sanitizedPlateNumber = sanitizedPlateNumber.replace(sanitizedPlateNumber.charAt(i), replaceList[j]);
                //kako je moguće da smo ovim postupkom maknuli
            }
        }
    }
    sanitizedPlateNumber = sanitizedPlateNumber.replace(/\s/g, "");
    return sanitizedPlateNumber;
}

function getDuration(start, end) {
    var rtrn = "";

    var delta = ConvertToJSDate(end) - ConvertToJSDate(start);
    var minutes = Math.round(delta / 1000 / 60);

    if (isNaN(minutes)) {
        rtrn = "-";
    } else {
        var days = Math.floor(minutes / 1440);
        var restMinutes = minutes % 1440;
        var hours = Math.floor(restMinutes / 60);
        var minutes = restMinutes % 60;

        // var hours = Math.floor(minutes / 60);
        // var minutes = minutes % 60;

        var dayLabel = "";

        if (days <= 0) {
            dayLabel = "";
        } else {
            dayLabel = days.toString() + "d ";
        }

        if (minutes > 0) {
            rtrn = hours + "h " + minutes + "min";
        } else {
            rtrn = hours + "h";
        }
    }

    return dayLabel + rtrn;
}

function ConvertToJSDate(dateTime) {
    var convertedDate = dateTime.replace(".", " ").replace(".", " ").replace(":", " ");
    var splittedDate = convertedDate.split(" ");
    return new Date(splittedDate[2], splittedDate[1], splittedDate[0], splittedDate[3], splittedDate[4]);
}

function openTemplate(template) {
    window.location = "#/paymentpreparation?ticket=" + template.Ticket + "&userdevicecardid=" + template.UserDeviceCardID + "&cityid=" + template.CityID + "&zoneid=" + template.ZoneID + "&zonetariffid=" + template.ZoneTariffID + "&quantity=" + template.Quantity;
}

function zeroPad(num, places) {
    return String(num).padStart(places, "0");
}





function htmlDecode(content) {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

function init(onSuccess, onError, forceUpdate) {

}

function showMessagesFromServer(message){
    if (message && message != "") {
        var splitter = "||";
        if (message.indexOf(splitter) == -1) {
            alert(message);
        } else {
            //više je poruka
            gMessages = message.split(splitter);
            br = 0;
            // for (var i = 0, len = messages.length; i < len; i++) {
            //     if (i != 0) {
            //         confirm(messages[i],translate('naslov'), null, translate('ok'));
            //     }
            // }
            recShow(gMessages)
        }
    }
}

var br;
var gMessages;

function recShow(messages){
    br = br + 1;
    if(br <= gMessages.length){
        alert(messages[br-1], translate('naslov'), translate('ok'), recShow);
    }
}

function disableBackButton(e) {
    //util.alert("disableBackButton");
    e.preventDefault();
}


function openWSPayForm(data, user, isTokenRequest, token, tokenNumber, lang, loadStartCallBack, exitCallBack, browserRef, setInBrowser) {

    let form = "<form name='pay' id='wspayform' action='" + data.URL + "' method='POST'>";
    form = form + "<input type='hidden' name='CustomerFirstName' value='" + user.firstName + "' />";
    form = form + "<input type='hidden' name='CustomerLastName' value='" + user.lastName + "' />";
    form = form + "<input type='hidden' name='CustomerAddress' value='" + user.address + "' />";
    form = form + "<input type='hidden' name='CustomerCity' value='" + user.city + "' />";
    form = form + "<input type='hidden' name='CustomerZIP' value='" + user.zip + "' />";
    form = form + "<input type='hidden' name='CustomerCountry' value='" + user.country + "' />";
    form = form + "<input type='hidden' name='CustomerPhone' value='" + user.phone + "' />";
    form = form + "<input type='hidden' name='CustomerEmail' value='" + user.email + "' />";
    form = form + "<input type='hidden' name='ShopID' value='" + data.ShopID + "' />";
    form = form + "<input id='ShoppingCartID' type='hidden' name='ShoppingCartID' value='" + data.PaymentDetails.ShoppingCartID + "'/>";
    form = form + "<input type='hidden' name='Version' value='" + data.ShopVersion + "' />";
    form = form + "<input type='hidden' name='TotalAmount' value='" + data.TotalAmount + "'/>";
    form = form + "<input id='Signature' type='hidden' name='Signature' value='" + data.Signature + "'/>";
    form = form + "<input type='hidden' name='ReturnURL' value='" + data.SuccessURL + "' />";
    form = form + "<input type='hidden' name='CancelURL' value='" + data.CancelURL + "' />";
    form = form + "<input type='hidden' name='ReturnErrorURL' value='" + data.ErrorURL + "' />";
    form = form + (isTokenRequest ? "<input type='hidden' name='IsTokenRequest' value='1' />": "");
    form = form + "<input type='hidden' name='Lang' value='" + lang.toUpperCase() + "' />";
    form = form + (data.AuthorizationToken ? "<input type='hidden' name='AuthorizationToken' value='" + data.AuthorizationToken + "' />" : "");
    form = form + (token ? "<input type='hidden' name='Token' value='" + token + "' />" : "");
    form = form + (tokenNumber ? "<input type='hidden' name='TokenNumber' value='" + tokenNumber + "' />" : "");

    form = form + "</form><script type='text/javascript'>document.getElementById('wspayform').submit();</script>";

    let htmlForm = form;

    let htmlMobileForm = "<html><head>" + "<meta http-equiv='Content-Security-Policy'  script-src='unsafe-inline';/>" + "</head><body>" + form + "</body></html>";

    var url = "data:text/html;base64," + btoa(unescape(encodeURIComponent(htmlMobileForm)));

    if (util.isMobileDevice()) {
        document.addEventListener("backbutton", disableBackButton, false);
        setInBrowser(true);
        browserRef = window.cordova.InAppBrowser.open(url, "_blank", "hidden=no,location=no,clearsessioncache=yes,clearcache=yes");
        browserRef.addEventListener("loadstart",(e) => loadStartCallBack(e, browserRef));
        browserRef.addEventListener("exit",(e) => exitCallBack());
    } else {
        //document.addEventListener("loadstop", loadStopCallBack);
        document.addEventListener("loadstart", loadStartCallBack);
        let body = document.getElementsByTagName("body")[0];
        body.insertAdjacentHTML("beforeend", htmlForm);
        //$("#wspayform").submit();
        //window.open(url, "_blank", "hidden=no,location=no,clearsessioncache=yes,clearcache=yes");
    }
}

function getPermissionForiOSNotifications() {
    const deviceOS = getPlatform();
    if (deviceOS == "ios") {
        if (window.FirebasePlugin) {
            window.FirebasePlugin.hasPermission(function (data) {
                if (data.isEnabled != true) {
                    window.FirebasePlugin.grantPermission(); // (iOS only);
                }
            });
        }
    }
}

export default util;
