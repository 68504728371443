import React, { useState, useContext, useEffect } from "react";

import { Tabs } from "antd";
import api from "../util/api";
import { Row, Col, Input } from "antd";
import TextField from "@mui/material/TextField";
import Util from "../util/util";
import database from "../util/database";
import Spinner from "./spinner";
import { Select } from "antd";
import ParkingTvrtkaEdit from "../components/parkingTvrtkaEdit";
import OperaterEdit from "../components/operaterEdit";
import GarazaEdit from "../components/garazaEdit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AppstoreOutlined, BankOutlined } from "@ant-design/icons";

const { Option } = Select;

function ParkingTvrtka(props) {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("default");
    const [adresa, setAdresa] = useState(null);
    const [operateri, setOperateri] = useState([]);
    const [tvrtka, setTvrtka] = useState();
    const [operater, setOperater] = useState();
    const [garaza, setGaraza] = useState();
    const [tipParkingSlots, setTipParkingSlots] = useState([]);
    const [changes, setChanges] = useState(null);
    const [mjesta, setMjesta] = useState([]);
    const [garaze, setGaraze] = useState([]);
    const [parkingTvrtkaNaziv, setParkingTvrtkaNaziv] = useState([]);
    const [activeTab, setActiveTab] = useState();

    const uloge = database.get("uloge");
    const isAdmin = uloge && (uloge.includes(2) || uloge.includes(1));

    useEffect(() => {
        if (!loaded) {
            const params = Util.getParametersFromUrl(window.location);

            let u = database.get("user");
            let uloge = u.uloge.split(",");

            let url = params.parkingTvrtkaID ? "GetParkingTvrtka?parkingTvrtkaID=" + params.parkingTvrtkaID : "GetParkingTvrtka?parkingTvrtkaID=" + database.get("user").parkingTvrtkaID;
            if(!params.parkingTvrtkaID && uloge.includes("1")){
                window.location = "#/parkingtvrtke";
                return;
            }


            setLoading(true);

            api.get(url, (data) => {
                setTvrtka(data);
                setGaraze(data.Garaze);
                setMjesta(database.get("mjesta"));
                setParkingTvrtkaNaziv(data.ParkingTvrtkaNaziv);
                setAdresa(data.PunaAdresa);
                setOperateri(data.Operateri);
                database.set("operaterID", data.OperaterID);
                database.set("parkingTvrtkaID", data.ParkingTvrtkaID);
                database.set("defaultCijena", data.DefaultCijena);
                setLoading(false);
            });
        }
        setLoaded(true);
    });

    const [deaktiviratiTvrtku, setDeaktiviratiTvrtku] = React.useState(false);
    const [deaktiviratiGarazu, setDeaktiviratiGarazu] = React.useState(false);
    const [deaktiviratiOperatera, setDeaktiviratiOperatera] = React.useState(false);

    const handleClickOpen = () => {
        setDeaktiviratiTvrtku(true);
    };

    const handleClose = (ok) => {
        setDeaktiviratiTvrtku(false);
        setDeaktiviratiGarazu(false);
        setDeaktiviratiOperatera(false);

        if (ok == 1) {
            api.post("DeactivateParkingTvrtka", tvrtka, (data) => {
                window.location = "#/parkingtvrtke";
            });
        }
        if (ok == 2) {
            api.post("DeactivateRotoGaraza", garaza, (data) => {
                const index = garaze.findIndex((x) => {
                    return x.RotoGarazaID == garaza.RotoGarazaID;
                });

                if (index > -1) {
                    let grz = [...garaze];
                    grz.splice(index, 1);
                    setGaraze(grz);
                }
                setLoading(false);
            });
        }

        if (ok == 3) {
            api.post("DeactivateOperater", operater, (data) => {
                const index = operateri.findIndex((x) => {
                    return x.OperaterID == operater.OperaterID;
                });

                if (index > -1) {
                    let lst = [...operateri];
                    lst.splice(index, 1);
                    setOperateri(lst);
                }
                setLoading(false);
            });
        }
    };

    function onMjestoChange(index, e) {
        setChanges(true);
        let newGarages = [...garaze];
        newGarages[index].MjestoID = e;
        setGaraze(newGarages);
    }

    function save() {
        const data = {
            Garaze: [...garaze],
            ParkingTvrtkaID: database.get("parkingTvrtkaID"),
        };
        setChanges(false);
        setLoading(true);
        api.post("SaveRotoGaraza", data, (newData) => {
            setGaraze(newData.Garaze);
            setLoading(false);
        });
    }

    function add() {
        setChanges(true);
        let slt = [...garaze];
        slt.push({ Naziv: "Garaža " + (slt.length + 1), RotoGarazaID: 0, MjestoID: 1, ParkingTvrtkaID: database.get("parkingTvrtkaID") });
        setGaraze(slt);
    }

    function onNazivChange(e, index) {
        setChanges(true);
        let newGarages = [...garaze];
        newGarages[index].Naziv = e.target.value;
        setGaraze(newGarages);
    }

    function onSlotClick(e, index) {
        window.location = "#/garaza?id=" + e;
    }

    function onTvrtkaEdit(data) {
        data.ParkingTvrtkaID = tvrtka.ParkingTvrtkaID;

        setMode("default");
        setLoading(true);
        api.post("AddParkingTvrtka", data, (data) => {
            setLoading(false);
            setTvrtka(data);
            setGaraze(data.Garaze);
            setParkingTvrtkaNaziv(data.ParkingTvrtkaNaziv);
            setAdresa(data.PunaAdresa);
            setOperateri(data.Operateri);
        });
    }

    function onCancel() {
        setMode("default");
    }

    function onOperaterEdit(data) {
        setMode("default");

        let newData = {
            Username: data.Username,
            Password: data.Password,
            ParkingTvrtkaID: tvrtka.ParkingTvrtkaID,
            OperaterID: operater && operater.OperaterID,
            ApiRotoGarazaID: data.ApiRotoGarazaID,
            Active: 1,
            Uloge: data.Uloge,
        };

        setLoading(true);
        api.post("AddOperater", newData, (data) => {
            if (data.Success) {
                if (operater.OperaterID == 0) {
                    let lst = [...operateri];
                    lst.push(data);
                    setOperateri(lst);
                } else {
                    let lst = [...operateri];
                    const index = lst.findIndex((x) => {
                        return x.OperaterID == data.OperaterID;
                    });

                    lst[index] = data;
                    setOperateri(lst);
                }
                setMode("default");
                setLoading(false);
            } else {
                setLoading(false);
                alert(data.ErrorMessage);
            }
        });
    }

    function onGarazaEdit(data) {
        setMode("default");
        data.ParkingTvrtkaID = tvrtka.ParkingTvrtkaID;
        setLoading(true);
        api.post("AddRotoGaraza", data, (data) => {
            let garazes = [...garaze];
            garazes.push(data);
            setGaraze(garazes);
            setLoading(false);
        });
    }

    function del() {
        setDeaktiviratiTvrtku(true);
    }

    function addGaraza() {
        setGaraza(null);
        setMode("editGaraza");
    }

    function tabChange(data) {
        setActiveTab(data);
    }

    //<BankOutlined style={{color:"#a8abaa"}}></BankOutlined>
    return loading ? (
        <Spinner></Spinner>
    ) : (
        <Row type="flex" style={{ minHeight: "80vh" }} className="fadeIn animated">
            {mode == "editTvrtka" && <ParkingTvrtkaEdit data={tvrtka} onCancel={onCancel} onSave={onTvrtkaEdit}></ParkingTvrtkaEdit>}
            {mode == "editOperater" && <OperaterEdit data={operater} garaze={garaze} onCancel={onCancel} onSave={onOperaterEdit}></OperaterEdit>}
            {mode == "editGaraza" && <GarazaEdit data={garaza} onCancel={onCancel} onSave={onGarazaEdit}></GarazaEdit>}
            {mode == "default" && (
                <Col span={24}>
                    <div className="fadeIn animated">
                        <h1 className="title">{parkingTvrtkaNaziv}</h1>
                        <h1 className="subtitle">{adresa}</h1>
                        <div className="divider"></div>
                        <div className="title-buttons">
                            {isAdmin && <button onClick={() => setMode("editTvrtka")}>Uredi</button>}
                            {isAdmin && <button onClick={del}>Deaktiviraj</button>}
                        </div>
                    </div>
                    <Tabs defaultActiveKey={1} activeKey={activeTab} onChange={tabChange}>
                        <Tabs.TabPane tab="Popis garaža" key={1}>
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th style={{ width: 300 }}>Naziv garaže</th>
                                        <th>Mjesto</th>
                                        <th style={{ width: "130px" }}> </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {garaze &&
                                        garaze.map((garaza, index) => {
                                            return (
                                                garaza.RotoGarazaID > -1 && (
                                                    <tr key={"rowslot" + index} onClick={(e) =>{onSlotClick(garaza.RotoGarazaID)}}>
                                                        <td>
                                                            {garaza.Naziv} {garaza && garaza.BojaSemaforaID == 3 && <div className="semaphore-small"></div>}{" "}
                                                        </td>
                                                        <td>{garaza.Adresa}</td>
                                                        <td>
                                                            {isAdmin && (
                                                                <button
                                                                    style={{ width: "90%" }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setGaraza(garaza);
                                                                        setDeaktiviratiGarazu(true);
                                                                    }}
                                                                >
                                                                    Deaktiviraj
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            );
                                        })}
                                    <tr>
                                        <td></td>
                                        <td className="right"></td>
                                        <td>
                                            {isAdmin && (
                                                <button style={{ width: "90%" }} onClick={(e) => addGaraza()}>
                                                    Dodaj garažu
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tabs.TabPane>
                       {isAdmin && <Tabs.TabPane tab="Popis operatera" key={2}>
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "230px" }}>Username</th>
                                        <th>Api roto garaža</th>
                                        <th style={{ width: "130px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {operateri &&
                                        operateri.map((operater, index) => {
                                            return (
                                                <tr
                                                    className="clickable"
                                                    key={"rowslotx3" + index}
                                                    onClick={() => {
                                                        if (!isAdmin) return;

                                                        setOperater(operater);
                                                        setMode("editOperater");
                                                    }}
                                                >
                                                    <td>{operater.Username}</td>
                                                    <td>
                                                        {operater &&
                                                            operater.ApiRotoGarazaID &&
                                                            operater.ApiRotoGarazaID != 0 &&
                                                            garaze &&
                                                            garaze.filter((x) => {
                                                                return x.RotoGarazaID == operater.ApiRotoGarazaID;
                                                            })[0].Naziv}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {isAdmin && (
                                                            <button
                                                                style={{ width: "90%" }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setOperater(operater);
                                                                    setDeaktiviratiOperatera(true);
                                                                }}
                                                            >
                                                                Deaktiviraj
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            {isAdmin && (
                                                <button
                                                    style={{ width: "90%" }}
                                                    onClick={() => {
                                                        setOperater({ OperaterID: 0 });
                                                        setMode("editOperater");
                                                    }}
                                                >
                                                    Dodaj operatera
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tabs.TabPane>}
                    </Tabs>

                    <br></br>
                    <br></br>
                    <br></br>
                    <Dialog open={deaktiviratiTvrtku} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Upozorenje!"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Deaktivacijom tvrtke, svi podaci o tvrtki biti će nedostupni, i svi operateri više neće imati pristup aplikaciji. </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleClose(0);
                                }}
                            >
                                ODUSTANI
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClose(1);
                                }}
                                autoFocus
                            >
                                RAZUMIJEM
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={deaktiviratiGarazu} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Upozorenje!"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Deaktivacijom garaže, svi podaci o garaži biti će nedostupni. </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleClose(0);
                                }}
                            >
                                ODUSTANI
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClose(2);
                                }}
                                autoFocus
                            >
                                RAZUMIJEM
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={deaktiviratiOperatera} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Upozorenje!"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Deaktivacijom operatera, svi podaci o operateru biti će nedostupni. </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleClose(0);
                                }}
                            >
                                ODUSTANI
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClose(3);
                                }}
                                autoFocus
                            >
                                RAZUMIJEM
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Col>
            )}
        </Row>
    );
}

export default ParkingTvrtka;
